import { createSelector } from 'reselect'

import {
  CONSTANTS_REQUEST,
  CONSTANTS_SUCCESS,
  CONSTANTS_FAILURE
} from './constants-actions'
import {
  ADD_DERIVED_INDICATORS,
  REMOVE_DERIVED_INDICATORS
} from './indicator-actions'

import { getFieldsForSelectedAccounts } from './fieldsReducer'
import { appStore } from '../../index'
import { getFilteredSampleArray } from '../../components/Analytics/analytics-reducer'
import { addIndicators, removeIndicators } from '../utils/indicatorHelpers'
import { indicatorSort } from '../utils/sortHelpers'
import { pscoreThresholds } from '../utils/thresholdHelpers'

// Initial State
const initialState = {
  isFetching: false,
  indicators: {
    shis: {},
    crop_pathogens: {},
    allByKey: {},
    chemistry_parameters: {},
    shiSubtypes: [],
    soilEnvSubtypes: []
  },
  thresholds: {
    byRegion: {}
  },
}

export const SHI_SUBTYPES = ['Nutrient Cycling', 'Beneficial Microbes']
export const SOIL_ENV_SUBTYPES = ['Microbiome Composition', 'Microbiome Activity']

// Listens for the general 'constants' endpoint action
// Reducer
export const indicatorReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CONSTANTS_REQUEST:
      return { ...state, isFetching: true }

    case CONSTANTS_SUCCESS: {
      const noCropText = (name)=>`To see crop-specific diseases caused by ${name}, please select an applicable crop`
      // create a flat all by key map for easy reference in mapStateToProps)
      let allByKey = { ...action.constants.shis, ...action.constants.chemistry_parameters }
      let cropIndicators = {}
      Object.entries(action.constants.crop_pathogens).forEach(([crop, indTypes]) => {
        cropIndicators[crop] = {
          'pathogens': indTypes.pathogens
        }
        // merge nematodes dict into pathogens dict for easy reference in maps/analytics and update to make disease agnostic
        !!indTypes.nematodes && Object.entries(indTypes.nematodes).forEach(([key, info]) => {
          cropIndicators[crop]['pathogens'][key] = info
          if (!(key in allByKey)) {
            allByKey[key] = {...info}
            allByKey[key]['text'] = noCropText(info.name)
            allByKey[key]['disease'] = null
          }
        })
        !!indTypes.pathogens && Object.entries(indTypes.pathogens).forEach(([pathKey, pathInfo]) => {
          if (!(pathKey in allByKey)) {
            allByKey[pathKey] = {...pathInfo}
            allByKey[pathKey]['text'] = noCropText(pathInfo.name)
            allByKey[pathKey]['disease'] = null
          }
        })
      })
      // TEMP: update PSCORE indicators to work as chem indicators until updates made to treat them seperately
      for (let param in action.constants.chemistry_parameters){
        action.constants.chemistry_parameters[param].indicator_type = 'chemistry'
      }
      return {
        ...state,
        isFetching: false,
        indicators: {
          'shis': action.constants.shis,
          'chemistry_parameters': action.constants.chemistry_parameters,
          'crop_pathogens': cropIndicators,
          'allByKey': allByKey,
          'shiSubtypes': SHI_SUBTYPES,
          'soilEnvSubtypes': SOIL_ENV_SUBTYPES
        },
        thresholds: {
          'byRegion': action.constants['regional_thresholds']
        }
      }
    }
    case CONSTANTS_FAILURE:
      return { ...state, isFetching: false, errorMessage: action.error }

    case ADD_DERIVED_INDICATORS: {
      const globalState = appStore.getState()
      let newIndicators = addIndicators({...state.indicators}, globalState.auth.user)
      return {
        ...state,
        indicators: newIndicators
      }
    }
    case REMOVE_DERIVED_INDICATORS: {
      let newIndicators = removeIndicators({...state.indicators})
      return {
        ...state,
        indicators: newIndicators
      }
    }
    default: {
      return state
    }
  }
}

// TODO: move these to somewhere higher in state than in the indicator reducer. entities.js? new selectors file? analyticsHelpers?
// indicators
export const getAllByKey = state => state.entities.indicators.indicators.allByKey
export const getCropPathogens = state => state.entities.indicators.indicators.crop_pathogens
const getIndicatorState = state => state.entities.indicators
// reportSettings
const getSelectedIndKey = state => state.reportSettings.selectedIndicatorKey
const getSelectedCrop = state => state.reportSettings.selectedCrop
// map Settings
const getMapIndKey = state => state.mapSettings.selectedIndicator
const getMapCrop = state => state.mapSettings.selectedCrop

export const getIndicatorDetails = (allByKey, cropPathogens, key, crop) => {
  let type = allByKey[key]?.indicator_type
  // crop-specific pathogens/nematodes must come from the crop-pathogen dict in order to have an accurate description
  // shis, chemistry descriptions are acurate without crop reference
  let cropPath = cropPathogens[crop]?.pathogens?.[key] //returns undefined if crop path is empty -- true for fallow, other, cover
  if (!['pathogen', 'nematode'].includes(type) || !cropPath) {
    return allByKey[key] || {}
  } else {
    return cropPathogens[crop]?.pathogens?.[key] || {}
  }
}
export const getIndicatorFromReportState = createSelector(
  getAllByKey,
  getCropPathogens,
  getSelectedIndKey,
  getSelectedCrop,
  getIndicatorDetails
)
export const getIndicatorFromMapState = createSelector(
  getAllByKey,
  getCropPathogens,
  getMapIndKey,
  getMapCrop,
  getIndicatorDetails
)

export const getThresholdDetails = (indicatorState, fieldEntities, filteredSamples, selectedIndicatorKey, selectedCrop) => {
  let high = null
  let low = 0
  let inverted = null
  let toolTipText = null
  let isAbsolute = false

  // filteredRegions is all the region names relevant to the filtered samples
  let filteredRegions = [...new Set(filteredSamples.map(sample => fieldEntities?.[sample.field_id]?.region_names).flat().filter(name => name !== 'United States'))]
  // if only one region, try to find a threshold for that one, otherwise fallback to united states
  let regionName = filteredRegions.length === 1 ? filteredRegions[0] : 'United States'
  // try to find the regionName in thresholds, otherwise fallback to united states
  let foundThreshold = Object.keys(indicatorState.thresholds.byRegion).includes(regionName)
    ? indicatorState.thresholds.byRegion[regionName]?.[selectedCrop]?.[selectedIndicatorKey]
    : indicatorState.thresholds.byRegion['United States']?.[selectedCrop]?.[selectedIndicatorKey]
  // if national region is used, determine if it was as a fallback from multiple fields or because of no region
  if (foundThreshold) {
    toolTipText = regionName === 'United States'
      ? filteredRegions.length > 1
        ? 'Fields displayed have different Regional Benchmarks. North American Benchmark is displayed.'
        : 'North American Benchmark'
      // if all fields encompassed by one region, use backend threshold type
      : foundThreshold?.threshold_label

    low = foundThreshold?.['low_benchmark']
    high = foundThreshold?.['high_benchmark']
    inverted = foundThreshold?.['inverted']
  } else if (pscoreThresholds[selectedIndicatorKey]) {
    ({ low, high, inverted, isAbsolute } = pscoreThresholds[selectedIndicatorKey])
  }
  return {
    low: low ? parseFloat(low.toFixed(4)) : 0,
    high: typeof high === 'number' ? parseFloat(high.toFixed(4)) : null,
    inverted,
    toolTipText,
    threshold_type: foundThreshold?.threshold_type,
    threshold_label: foundThreshold?.threshold_label,
    regionName,
    isAbsolute
  }
}
export const getThresholdFromState = createSelector(
  getIndicatorState,
  getFieldsForSelectedAccounts,
  getFilteredSampleArray,
  getSelectedIndKey,
  getSelectedCrop,
  getThresholdDetails
)

export const getThreshold = (filteredSamples = null, fieldEntities = null, selectedIndKey, selectedCrop) => {
  let globalState = appStore.getState()
  // use global state for samples/fields unless using local filters/fields in CumulativePrintReport

  let samples = filteredSamples ? filteredSamples : getFilteredSampleArray(globalState)
  let fields = fieldEntities ? fieldEntities : getFieldsForSelectedAccounts(globalState)
  let indicatorsState = getIndicatorState(globalState)
  return getThresholdDetails(indicatorsState, fields, samples, selectedIndKey, selectedCrop)
}


export const parseThresholdShape = (threshold, regionName = '') => {
  let low,
      high,
      inverted,
      threshold_type,
      threshold_label
  if (threshold) {
    low = threshold?.['low_benchmark']
    high = threshold?.['high_benchmark']
    inverted = threshold?.['inverted']
    threshold_type = threshold?.['threshold_type']
    threshold_label = threshold?.['threshold_label']
  }
  return {
    regionName: threshold_type === 'default' ? 'United States' : regionName,
    low: low ? parseFloat(low.toFixed(4)) : 0,
    high: typeof high === 'number' ? parseFloat(high.toFixed(4)) : null,
    inverted,
    threshold_type,
    threshold_label
  }
}

export const getThresholdForRegion = (regionName, selectedIndicatorKey, selectedCrop) => {
  let globalState = appStore.getState()
  let indicators = getIndicatorState(globalState)
  let foundThreshold = indicators.thresholds.byRegion[regionName]
    ? indicators.thresholds.byRegion[regionName]?.[selectedCrop]?.[selectedIndicatorKey]
    : indicators.thresholds.byRegion['United States']?.[selectedCrop]?.[selectedIndicatorKey]
  return parseThresholdShape(foundThreshold, regionName)
}
export const getThresholdForField = (fieldId, selectedIndicator, selectedCrop) => {
  let globalState = appStore.getState()
  let fields = getFieldsForSelectedAccounts(globalState)
  let field = fields?.[fieldId]
  if (!field) return getThresholdForRegion() //error out elegantly
  let regionName = field.region_names.find(region => region !== 'United States') || 'United States'
  return getThresholdForRegion(regionName, selectedIndicator, selectedCrop)
}
export const getOrderedRegions = () => {
  let globalState = appStore.getState()
  let indicators = getIndicatorState(globalState)
  let regional = Object.keys(indicators.thresholds.byRegion).sort()
  let nationalIndex = regional.findIndex(region => region === 'United States')
  let national = regional.splice(nationalIndex, 1)
  return national.concat(regional)
}

export const getFilteredIndicatorArray = (indicatorsState, indType, cropName = null) => {
  let indicatorsArray = Object.values({ ...indicatorsState.indicators.allByKey })
  const getIndicatorsForSubtypes = (subtypes) => {
    return subtypes.reduce((acc, type) => {
      const indicators = indicatorsArray
        .filter(({subtype}) => type === subtype)
        .sort(indicatorSort)
      acc = acc.concat(indicators)
      return acc
    }, [])
  }
  if (indType === 'pathogen') {
    // return all pathogens/nematodes if no crop specific indicators else crop-specific indicators only
    let cropPaths = Object.values(indicatorsState.indicators.crop_pathogens?.[cropName]?.pathogens || {})
    indicatorsArray = cropPaths.length
      ? cropPaths
      : indicatorsArray.filter(i => ['pathogen', 'nematode'].includes(i.indicator_type))
  } else if (indType === 'soilEnv') {
    return getIndicatorsForSubtypes(SOIL_ENV_SUBTYPES)
  } else if (indType === 'shi') {
    return getIndicatorsForSubtypes(SHI_SUBTYPES)
  } else {
    indicatorsArray = indicatorsArray.filter(i => i.indicator_type === indType)
  }
  return indicatorsArray.sort(indicatorSort)
}
