import styled from 'styled-components'

export const StyledNavBar = styled.div`
	.navbar{
		background-color: #ffffff;
		width: 100%;
		z-index: 2;
		box-shadow: 0 2px 10px #e3e9f0;
		position: inherit;
    padding: 5px 5%;
    font-size: 1.3em;
    font-weight: 500;

		.navbar-brand{
	    img{
	      width: 120px;
	      height: auto;
	    }
	  }

		.nav-item{
			a {
		    text-decoration: none;
		    color: #6d6e71;
		    padding: .5rem;
		    display: block;
		    background-color: transparent;

		    &.active{
					color: ${props => props.theme.colors.primary};
					cursor: auto;
				}
			}
		}

    .dropdown{
			&.nav-item{
				padding-bottom: 10px;
			}

			.nav-link{
				display: flex;
				flex-flow: row;
				align-items: center;
				color: #6d6e71;

				.down-arrow{
					fill: #6d6e71;
				  display: flex;
				  margin-left: 8px;
				  transition: fill 150ms;
				}
			}

			.dropdown-menu{
				.dropdown-item{
					&:hover{
						background-color: #eeeeee;
						a{
							color: ${props => props.theme.colors.primary};
						}
					}
					a {
						font-size: 14px;
						line-height: normal;
				    text-decoration: none;
				    color: #6d6e71;
				    padding: .5rem;
				    display: block;
				    background-color: transparent;
					}
				}
			}
		}
	}

	@media screen and (max-width: 767px){
		// override bootstrap
		.navbar-light .navbar-nav .show>.nav-link,
		.navbar-light .navbar-nav .nav-link:hover{
			color: #6d6e71;
		}
	}

  @media screen and (min-width: 768px){
    .navbar{
      padding: 5px 60px;
    }
  }

	@media screen and (min-width: 992px){

		// defining navbar height for hover effects
		.navbar{
			height: 66px;
			background-color: #ffffff;
      padding: 0 60px;

			.dropdown{
				&.nav-item{
					padding-bottom: 0;
				}
			}

		}
		.navbar-nav{
			// parent of navbar-collapse bellow
			height: 100%;
			margin-left: 35px;
		}

		.navbar-collapse {
			// allows for hover effect to cover top to bottom of navbar
			height: 100% !important;

			.nav-item {
				padding: 0 16px;
				height: 100%;
				align-items: center;
				display: flex;
				transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

				&:hover, &.show {
					background-color: #eeeeee;
					a, .down-arrow{
						color: ${props => props.theme.colors.primary};
						fill: ${props => props.theme.colors.primary};
					}
				}
			}
		}
	}
`

export const StyledSideNavBar = styled.div`
	height: 100vh;
	width: 6.7% !important;
	background: ${props => props.theme.colors.navBarBlue};
	min-width: 97px !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	z-index: 3;
	.link-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	.navbar-brand{
		margin-right: 0 !important;
		margin-bottom: 30px;
		margin-top: 15px;
	    img {
	      width: 48px;
				height: 32px;
	    }
	  }
	.link-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-right: 0 !important;
	}
`

export const StyledUserLinks = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-item: center;
	.user-wrapper {
		display: flex;
		flex-direction: row;
		align-item: center;
		justify-content: center;
		margin-bottom: 10px;
		position: relative;
		&:hover .dropdown-menu {
			display: flex;
		}
	}
	.toggle {
		width: 100% !important;
		height: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
	}
	.user {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: #BB58EA;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: Lato;
		font-weight: 900;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		color: ${props => props.theme.colors.white};
	}
	.dropdown-menu {
		display: none;
		position: absolute;
		width: 200px;
		box-shadow: 0px 4px 12px rgba(61, 86, 85, 0.25) !important;
		border-radius: 10px !important;
		border: 0;
		top: -140px !important;
		left: ${props => props.isViewMode ? '0' : '-15px'};
		flex-direction: column;
		justify-content: center;
    align-items: flex-start;
    & > div {
      padding-left: 16px;
      :hover, :active{
        background-color: ${props => props.theme.colors.linkWater};
      }
    }
    & > span {
      margin-left: 16px;
    }
		.dropdown-item {
      cursor: pointer;
			font-family: Montserrat;
			font-size: 18px;
			line-height: 24px;
			& > a {
        text-decoration: none;
        color: ${props => props.theme.colors.sharpDark};
      }
    }
	}
`

export const StyledFarmViewDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background: ${props => props.theme.colors.deepDark};
	padding-top: 10px;
	padding-bottom: 5px;
	span {
		font-family: Lato;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.25px;
		color: ${props => props.theme.colors.janeGray};
		width: 90%;
		text-align: center;
	}
	.farm-name {
		font-weight: bold;
		color: ${props => props.theme.colors.white};
		margin-bottom: 10px;
	}
	button {
		width: 90%;
		font-family: Lato;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		text-transform: uppercase;
		color: ${props => props.theme.colors.sharpBlue};
		border: 1px solid ${props => props.theme.colors.sharpBlue};
		border-radius: 4px;
		height: 36px;
		cursor: pointer !important;
		margin-bottom: 10px;
		outline: none;
		background: transparent;
	}
`

export const StyledSideNavBarNavItem = styled.div`
  margin: 10px 0;
  padding: 0 5px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	a {
		font-family: Lato;
		font-weight: 500;
		font-size: 18px;
		line-height: 20px;
		text-align: center;
		color: ${props => props.theme.colors.tableGray};
		text-decoration: none !important;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		&:hover {
			color: ${props => props.theme.colors.lightTeal};
			svg {
				fill: ${props => props.theme.colors.lightTeal};
			}
		}
		&.open {
			color: ${props => props.theme.colors.lightTeal};
			svg {
				fill: ${props => props.theme.colors.lightTeal};
			}
		}
	}
	svg {
		fill: #DADADA;
	}
`

export const StyledAbsoluteStickyWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
`
