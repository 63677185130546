export const TOGGLE_UNRELEASED_ACCESS = 'TOGGLE_UNRELEASED'
export const SET_BETA_ACCESS = 'SET_BETA_ACCESS'

export function toggleUnreleasedFeatures(){
  return {
    type: TOGGLE_UNRELEASED_ACCESS,
  }
}

export function setBetaAccess(bool){
  return {
    type: SET_BETA_ACCESS,
    access: bool
  }
}
