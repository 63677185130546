import { TOGGLE_UNRELEASED_ACCESS, SET_BETA_ACCESS } from './meta-actions'
import { API_ERROR } from '../common/utils/apiHelpers'

const initialState = {
  errorStatus: null,
  unreleasedVisible: false,
  betaVisible: false
}

function metaReducer(state = initialState, action) {

  switch (action.type) {
    case TOGGLE_UNRELEASED_ACCESS:
      return {
        ...state,
        unreleasedVisible: !state.unreleasedVisible
      }
    case API_ERROR:
      return {
        ...state,
        errorStatus: action.status
      }
    case SET_BETA_ACCESS:
      return {
        ...state,
        betaVisible: action.access
      }


    default: return state
  }
}

export default metaReducer
