import { getRisk } from './thresholdHelpers'


export const checkObjectAgainstFilters = (obj, filters, filterDefinition, ignoreEmpty) => {
  for (let key in filters) {
    let keyValue = filters[key]
    if (!(key in filterDefinition) || filters[key] === undefined) {
      continue
    } else if (ignoreEmpty && ((Array.isArray(keyValue) && !keyValue.length) || keyValue === '')) {
      continue
    } else if (!(filterDefinition[key](obj, keyValue, filters))) {
      return false
    }
  }
  return true
}


/**
* Filtering function generator for filtering an array of objects given a set of accepted filter keys
* When child function called, pass in a Filter object with a subset of the keys set in filterDefinition
* @param {Object.<string, function>} filterDefinition filter functions available to be run on objects
* @returns {function} function that takes in an object array and a object defining the values of the filter
*/
export function filterObjectsFactory(_filterDefinition) {
  /**
   * Filtering function
   * @param {Object[]} objectArray Array to be filtered
   * @param {object} filters values of the filters to be applied to the array objects
   */
  return (objectArray, filters, filterDefinition = _filterDefinition, ignoreEmpty = false) => {
    return objectArray.filter(s => {
      return checkObjectAgainstFilters(s, filters, filterDefinition, ignoreEmpty)
    })
  }
}

const sampleFilterKeys = {
  fields: (sample, key) => typeof key[0] === 'string' ? key.includes(sample.field_id) : key.map(f=>f.id).includes(sample.field_id), // array of accepted field or field ids
  farms: (sample, key) => typeof key[0] === 'string' ? key.includes(sample.farm_id) : key.map(f=>f.id).includes(sample.farm_id), //array of accepted farms or farm ids
  samplingDateStart: (sample, key) => !key || sample.sampling_date >= key,
  samplingDateEnd: (sample, key) => !key || sample.sampling_date <= key,
  hasMetadata: (sample, key) => sample.is_missing_metadata === !key,
  id: (sample, key) => key.includes(sample.id),
  plantedCrop: (sample, key, filters) => !!key.filter(value => {
    let baseArr = [sample.current_crop, sample.previous_crop]
    if (filters.includeNext){
      baseArr.push(sample.next_crop)
    }
    return baseArr.includes(value)
  }).length,
  sampleTags: (sample, key) => !!key.filter(value => sample.sample_tags.includes(value)).length,
  processed: (sample, key) => !!(sample.processed_date || sample.chem_processed_date || sample.processing_final_failure_date) === key,
  field: (sample, key) => key.includes(sample.field), //filter on NAME not object or id
  farm: (sample, key) => key.includes(sample.farm), //filter on NAME not object or id
}


/*
* Centralized sample filtering for samples.
*/
// array filter
export const samplesFilter = filterObjectsFactory(sampleFilterKeys)
// single sample filter
export const checkSampleAgainstFilters = (sample, filters) => checkObjectAgainstFilters(sample, filters, sampleFilterKeys)

/**
 * Calculates various statistics given a set of samples.
 * @param {Object[]} samples array of sample objects
 * @param {object} thresholdObj object that contains a threshold definition for indicator (inverse,  high low keys)
 * @param {object} indicator indicator definition
 */
export const getSampleStats = (samples, thresholdObj, indicator) => {
  let indKey = indicator.key
  let hasPending = false
  //get all processed non-null samples
  samples = samples.filter(sample => {
    // only filter no metadata samples for metadata
    if (sample.is_missing_metadata && indicator.indicator_type !== 'chemistry') {
      return false
    }
    // don't include pending but mark as pending
    if (indicator.indicator_type === 'chemistry' ? sample.has_chem_pending : sample.has_bio_pending) {
      hasPending = true
      return false
    }
    // exclude final failures
    if (sample.processing_final_failure_date){
      return false
    }
    return typeof sample.results[indKey] === 'number'
  })
  // if no non pending non none samples, return empty pending field
  if (!samples.length) return {
    highestRiskValue: null,
    averageValue: null,
    hasPending: true,
    highestRiskPercent: null,
    averagePercent: null,
    peakRiskLevel: null,
    averageRiskLevel: null
  }
  let stats = {
    lowValue: Math.min(...samples.map(sample => sample.results[indKey] !== undefined ? sample.results[indKey] : Number.MAX_VALUE)),
    highValue: Math.max(...samples.map(sample => sample.results[indKey] !== undefined ? sample.results[indKey] : -1)),
    highestRiskValue: samples.reduce((memo, sample) => (
      (thresholdObj.inverted === (memo > sample.results[indKey]))
        ? memo : sample.results[indKey]), thresholdObj.inverted ? 0 : Number.MAX_VALUE),
    averageValue: samples.reduce((memo, sample) => (memo + sample.results[indKey]), 0) / samples.length,
    hasPending,

  }
  if (thresholdObj.high === 0) {
    stats.highestRiskPercent = stats.highestRiskValue === 0 ? 0 : 1
    stats.averagePercent = stats.averageValue === 0 ? 0 : 1
    stats.peakRiskLevel = stats.highestRiskValue === 0 ? 'medium' : 'high'
    stats.averageRiskLevel = stats.highestRiskValue === 0 ? 'medium' : 'high'
  } else {
    stats.highestRiskPercent = stats.highestRiskValue / (thresholdObj.high)
    stats.averagePercent = stats.averageValue / (thresholdObj.high)
    stats.peakRiskLevel = getRisk(stats.highestRiskValue, thresholdObj)
    stats.averageRiskLevel = getRisk(stats.averageValue, thresholdObj)
  }
  return stats
}


export const addStatsToField = (field, samples, thresholdObj, indicator) => {
  const stats = getSampleStats(samples, thresholdObj, indicator)
  return {
    ...field,
    ...stats,
    regionName: thresholdObj.threshold_type === 'default' ? 'United States' : field.region_names.find(region => region !== 'United States')
  }
}



