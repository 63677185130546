export const SET_MAP_SETTINGS = 'SET_MAP_SETTINGS'
export const CLEAR_MAP_SETTINGS = 'CLEAR_MAP_SETTINGS'
export const setMapSettings = (settings = {}) => ({
  type: SET_MAP_SETTINGS,
  settings
})


export const clearMapSettings = () => ({
  type: CLEAR_MAP_SETTINGS,
})
