import React from 'react'
import { withRouter } from 'react-router'
import lettuce from '../../assets/images/trace_lettuce.png'
import { StyledNotFoundPage } from './styles'
import PropTypes from 'prop-types'


const NotFound = (props) => {
  return (
    <StyledNotFoundPage>
      <img src={lettuce} alt="Trace Genomics 404 Lettuce" />
      <h1>404</h1>
      <h3>Sorry, the page you requested cannot be found.</h3>
      <button onClick={() => props.history.go(-1)}>Go Back</button>
    </StyledNotFoundPage>
  )
}

NotFound.propTypes = {
  history: PropTypes.shape({
    go: PropTypes.func,
  }).isRequired
}
export default withRouter(NotFound)
