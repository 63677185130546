import { MODAL_HIDE, MODAL_SHOW } from './modalActions'

const initialState = {
  modalState: false,
  modalStateTubeId: false,
  modalType: ''
}

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_HIDE: {
      return { ...state, modalState: false, modalType: '' }
    }
    case MODAL_SHOW: {
      return { ...state, modalState: true, modalType: action.modalType }
    }
    default: return state
  }
}
