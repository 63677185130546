import {
  getAccountApiHelper,
  postBaseApiHelper,
  getBaseApiHelper,
  deleteBaseApiHelper } from '../../common/utils/apiHelpers'
import { addFlashMessage } from '../../components/FlashMessages/messages-actions'
import { appStore } from '../../index'

export const RESET_SERVER_ERROR = 'RESET_SERVER_ERROR'

export const ORDERS_REQUEST = 'ORDERS_REQUEST'
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS'
export const ORDERS_FAILURE = 'ORDERS_FAILURE'

export const ORDER_REQUEST = 'ORDER_REQUEST'
export const ORDER_SUCCESS = 'ORDER_SUCCESS'
export const ORDER_FAILURE = 'ORDER_FAILURE'

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'

export const CREATE_ORDERS_REQUEST = 'CREATE_ORDERS_REQUEST'
export const CREATE_ORDERS_SUCCESS = 'CREATE_ORDERS_SUCCESS'
export const CREATE_ORDERS_FAILURE = 'CREATE_ORDERS_FAILURE'

export const ORDER_SAMPLE_POST_REQUEST = 'ORDER_SAMPLE_POST_REQUEST'
export const ORDER_SAMPLE_POST_SUCCESS = 'ORDER_SAMPLE_POST_SUCCESS'
export const ORDER_SAMPLE_POST_FAILURE = 'ORDER_SAMPLE_POST_FAILURE'

export const MULTIPLE_ORDER_SAMPLES_POST_REQUEST = 'MULTIPLE_ORDER_SAMPLES_POST_REQUEST'
export const MULTIPLE_ORDER_SAMPLES_POST_SUCCESS = 'MULTIPLE_ORDER_SAMPLES_POST_SUCCESS'
export const MULTIPLE_ORDER_SAMPLES_POST_FAILURE = 'MULTIPLE_ORDER_SAMPLES_POST_FAILURE'

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE'

export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE'


export const resetServerError = () => {
  return {
    type: RESET_SERVER_ERROR
  }
}

export const setSelectedPage = selectedPage => {
  return {
    type: SET_SELECTED_PAGE,
    selectedPage
  }
}

/**
Get all orders associated with an account (both ordered by account and for account)
**/
function ordersRequest(page) {
  return {
    type: ORDERS_REQUEST,
    isFetching: true,
    page
  }
}

function ordersSuccess({meta, orders}, page, hasNewFilters) {
  return {
    type: ORDERS_SUCCESS,
    meta,
    orders,
    page,
    hasNewFilters
  }
}

function ordersFailure(error, page) {
  return {
    type: ORDERS_FAILURE,
    error,
    page
  }
}

export function getAccountOrders({ page = 1, hasNewFilters=false, growerIds=[], farmIds=[], fieldIds=[], createdStartDate='', createdEndDate='' } = {}) {
  const growerIdsStr = growerIds.join('|')
  const farmIdsStr = farmIds.join('|')
  const fieldIdsStr = fieldIds.join('|')
  return dispatch => {
    dispatch(ordersRequest(page))
    return getAccountApiHelper(`/order?page=${page}&grower_account_id=${growerIdsStr}&farm_id=${farmIdsStr}&field_id=${fieldIdsStr}&start_date=${createdStartDate}&end_date=${createdEndDate}`)
      .then(response => dispatch(ordersSuccess(response.data, page, hasNewFilters)))
      .catch(rejected => dispatch(ordersFailure(rejected, page)))
  }
}

/**
Create an order
**/
function createOrderRequest() {
  return {
    type: CREATE_ORDER_REQUEST
  }
}

function createOrderSuccess(order) {
  return {
    type: CREATE_ORDER_SUCCESS,
    order
  }
}

function createOrderFailure(error) {
  return {
    type: CREATE_ORDER_FAILURE,
    error
  }
}

/**
Create multiple orders
**/
function createOrdersRequest() {
  return {
    type: CREATE_ORDERS_REQUEST
  }
}

function createOrdersSuccess(order) {
  return {
    type: CREATE_ORDERS_SUCCESS,
    order
  }
}

function createOrdersFailure(error) {
  return {
    type: CREATE_ORDERS_FAILURE,
    error
  }
}

export function postOrder(data) {
  return dispatch => {
    dispatch(createOrderRequest())
    return postBaseApiHelper('/orders', data)
      .then(resp => {
        dispatch(createOrderSuccess(resp.data))
        dispatch(setSelectedPage(1))
        return resp.data
      })
      .catch(rejected => {
        dispatch(createOrderFailure(rejected))
        return Promise.reject(rejected)
      })
  }
}

export const postOrders = (payloads) => {
  return dispatch => {
    dispatch(createOrdersRequest())
    const createOrder = payload => postBaseApiHelper('/orders', payload)
    return Promise.all(payloads.map(createOrder))
      .then(responses => {
        return dispatch(createOrdersSuccess(responses.map(res => res.data)))
      })
      .catch(rejected => {
        dispatch(createOrdersFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

/**
Add samples to an order
**/
function postOrderSampleRequest() {
  return {
    type: ORDER_SAMPLE_POST_REQUEST,
  }
}

function postOrderSampleSuccess(orderId, payload) {
  return {
    type: ORDER_SAMPLE_POST_SUCCESS,
    orderId,
    samples: payload.samples,
    sampleErrors: payload.sampleErrors,
    deletions: payload.deletions
  }
}

function postOrderSampleFailure(error) {
  return {
    type: ORDER_SAMPLE_POST_FAILURE,
    serverError: error,
  }
}

/**
Add samples to multiple orders
**/
function postMultipleOrderSamplesRequest() {
  return {
    type: MULTIPLE_ORDER_SAMPLES_POST_REQUEST,
  }
}

function postMultipleOrderSamplesSuccess(sampleData) {
  return {
    type: MULTIPLE_ORDER_SAMPLES_POST_SUCCESS,
    sampleData
  }
}

function postMultipleOrderSamplesFailure(error) {
  return {
    type: MULTIPLE_ORDER_SAMPLES_POST_FAILURE,
    serverError: error,
  }
}

export function postOrderSamples(orderId, data) {
  return dispatch => {
    dispatch(postOrderSampleRequest())
    return postBaseApiHelper(`/orders/${orderId}/samples`, data)
      .then(resp => {
        let postOrderSampleResponse = {
          samples: resp.data.samples,
          sampleErrors: resp.data.errors,
          deletions: resp.data.deletions
        }
        dispatch(postOrderSampleSuccess(orderId, postOrderSampleResponse))
        return Promise.resolve(postOrderSampleResponse)
      })
      .catch(rejected => {
        dispatch(postOrderSampleFailure(rejected))
        return Promise.reject(rejected)
      })
  }
}

export const postMultipleOrderSamples = (payloads) => {
  return dispatch => {
    dispatch(postMultipleOrderSamplesRequest())
    const postOrderSamples = ({orderId, data}) => postBaseApiHelper(`/orders/${orderId}/samples`, data)
    return Promise.all(payloads.map(postOrderSamples))
      .then(responses => {
        const postOrderSampleResponses = responses.map(resp => ({
          samples: resp.data.samples,
          sampleErrors: resp.data.errors,
          deletions: resp.data.deletions,
          orderId: resp.data.samples[0].order_id
        }))
        return dispatch(postMultipleOrderSamplesSuccess(postOrderSampleResponses))
      })
      .catch(rejected => {
        dispatch(postMultipleOrderSamplesFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

/**
Get an order by ID
**/
function getOrderRequest() {
  return {
    type: ORDER_REQUEST
  }
}

function getOrderSuccess(order) {
  return {
    type: ORDER_SUCCESS,
    order
  }
}

function getOrderFailure(error) {
  return {
    type: ORDER_REQUEST,
    error
  }
}

export function getOrderById(orderId) {
  return dispatch => {
    dispatch(getOrderRequest())
    return getBaseApiHelper(`/orders/${orderId}`)
      .then(resp => {
        dispatch(getOrderSuccess(resp.data))
        return resp.data
      })
      .catch(error => {
        dispatch(getOrderFailure(error))
        return Promise.reject(error)
      })
  }
}

/**
Delete an order by ID
**/
function deleteOrderRequest() {
  return {
    type: DELETE_ORDER_REQUEST
  }
}

function deleteOrderSuccess(orderId) {
  return {
    type: DELETE_ORDER_SUCCESS,
    orderId
  }
}

function deleteOrderFailure(error) {
  return {
    type: DELETE_ORDER_FAILURE,
    error
  }
}

export function deleteOrder(orderId, showMessage = true) {
  return dispatch => {
    dispatch(deleteOrderRequest())
    return deleteBaseApiHelper(`/orders/${orderId}`)
      .then(resp => {
        const { orders } = appStore.getState().entities
        const isLastOrderOnPage = orders.byPage[orders.selectedPage]?.length === 1
        dispatch(deleteOrderSuccess(orderId))
        if (showMessage) {
          // if last order on page is deleted, set selected page to n - 1
          if (isLastOrderOnPage) {
            dispatch(setSelectedPage(orders.selectedPage - 1))
          }
          dispatch(addFlashMessage({
            type: 'success',
            title: 'Delete Success',
            text: 'Successfully deleted order and any associated sample data.'
          }))
        }
        return resp.data
      })
      .catch(error => {
        dispatch(deleteOrderFailure(error.response))
        return Promise.reject({ error: error.response })
      })
  }
}
