import {
  getBaseApiHelper,
  postBaseApiHelper,
  patchBaseApiHelper,
  deleteBaseApiHelper
} from '../../common/utils/apiHelpers'
import { getAccountId } from '../utils/apiHelpers'
import { appStore } from '../../index'

export const SELECT_FARM_FOR_DETAIL = 'SELECT_FARM_FOR_DETAIL'

export const GET_ACCOUNT_FARMS_REQUEST = 'GET_ACCOUNT_FARMS_REQUEST'
export const GET_ACCOUNT_FARMS_FAILURE = 'GET_ACCOUNT_FARMS_FAILURE'
export const GET_ACCOUNT_FARMS_SUCCESS = 'GET_ACCOUNT_FARMS_SUCCESS'

export const GET_FARM_REQUEST = 'GET_FARM_REQUEST'
export const GET_FARM_SUCCESS = 'GET_FARM_SUCCESS'
export const GET_FARM_FAILURE = 'GET_FARM_FAILURE'

export const FARM_POST_REQUEST = 'FARM_POST_REQUEST'
export const FARM_POST_SUCCESS = 'FARM_POST_SUCCESS'
export const FARM_POST_FAILURE = 'FARM_POST_FAILURE'

export const FARM_PATCH_REQUEST = 'FARM_PATCH_REQUEST'
export const FARM_PATCH_SUCCESS = 'FARM_PATCH_SUCCESS'
export const FARM_PATCH_FAILURE = 'FARM_PATCH_FAILURE'

export const DELETE_FARM_REQUEST = 'DELETE_FARM_REQUEST'
export const DELETE_FARM_SUCCESS = 'DELETE_FARM_SUCCESS'
export const DELETE_FARM_FAILURE = 'DELETE_FARM_FAILURE'

export const FARMS_POST_REQUEST = 'FARMS_POST_REQUEST'
export const FARMS_POST_SUCCESS = 'FARMS_POST_SUCCESS'
export const FARMS_POST_FAILURE = 'FARMS_POST_FAILURE'

// Action Creators

export const selectFarmForDetail = farmId => ({
  type: SELECT_FARM_FOR_DETAIL,
  farmId
})

const getAccountFarmsRequest = () => ({
  type: GET_ACCOUNT_FARMS_REQUEST,
  isFetching: true
})

const getAccountFarmsSuccess = (payload, accountId) => ({
  type: GET_ACCOUNT_FARMS_SUCCESS,
  payload,
  accountId
})

const getAccountFarmsFailure = payloadError => ({
  type: GET_ACCOUNT_FARMS_FAILURE,
  error: payloadError
})

export const getAccountFarms = (accountId, useSelectedAccounts=false) => {
  if (useSelectedAccounts && !accountId) {
    const store = appStore.getState()
    accountId = store.auth.selectedAccounts?.[0] || getAccountId()
  } else {
    accountId = accountId || getAccountId()
  }
  accountId = accountId || getAccountId()
  return dispatch => {
    dispatch(getAccountFarmsRequest())
    return getBaseApiHelper(`/account/${accountId}/farms`)
      .then(response => {
        dispatch(getAccountFarmsSuccess(response.data, accountId))
      })
      .catch(rejected => {
        dispatch(getAccountFarmsFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

const getFarmRequest = () => ({
  type: GET_FARM_REQUEST
})

const getFarmSuccess = (farm) => ({
  type: GET_FARM_SUCCESS,
  farm
})

const getFarmFailure = error => ({
  type: GET_FARM_FAILURE,
  error
})

export const getFarm = farmId => {
  return dispatch => {
    dispatch(getFarmRequest())
    return getBaseApiHelper(`/farms/${farmId}`)
      .then(response => {
        dispatch(getFarmSuccess(response.data))
      })
      .catch(error => {
        dispatch(getFarmFailure(error.response))
      })
  }
}

// single farm
const farmPostRequest = () => ({
  type: FARM_POST_REQUEST,
  isFetching: true
})

const farmPostSuccess = payload => ({
  type: FARM_POST_SUCCESS,
  payload
})

const farmPostFailure = payloadError => ({
  type: FARM_POST_FAILURE,
  error: payloadError
})

// multiple farms
const farmsPostRequest = () => ({
  type: FARMS_POST_REQUEST,
  isFetching: true
})

const farmsPostSuccess = payload => ({
  type: FARMS_POST_SUCCESS,
  payload
})

const farmsPostFailure = payloadError => ({
  type: FARMS_POST_FAILURE,
  error: payloadError
})

export const createFarm = (name, account_id) => {
  return dispatch => {
    dispatch(farmPostRequest())
    return postBaseApiHelper('/farms', { name, account_id })
      .then(response => {
        return dispatch(farmPostSuccess(response.data))
      })
      .catch(rejected => {
        dispatch(farmPostFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

export const createFarms = (account_id, names) => {
  return dispatch => {
    dispatch(farmsPostRequest())
    const createFarm = name => postBaseApiHelper('/farms', { name, account_id })
    return Promise.all(names.map(createFarm))
      .then(responses => {
        return dispatch(farmsPostSuccess(responses.map(res => res.data)))
      })
      .catch(rejected => {
        dispatch(farmsPostFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

const farmPatchRequest = () => ({
  type: FARM_PATCH_REQUEST,
  isFetching: true
})

const farmPatchSuccess = payload => ({
  type: FARM_PATCH_SUCCESS,
  payload
})

const farmPatchFailure = payloadError => ({
  type: FARM_PATCH_FAILURE,
  error: payloadError
})

export const updateFarm = (farmId, { name, account_id }) => {
  return dispatch => {
    dispatch(farmPatchRequest())
    return patchBaseApiHelper(`/farms/${farmId}`, { name, account_id })
      .then(response => {
        return dispatch(farmPatchSuccess(response.data))
      })
      .catch(rejected => {
        dispatch(farmPatchFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

const deleteFarmRequest = () => {
  return {
    type: DELETE_FARM_REQUEST,
    isFetching: true
  }
}

const deleteFarmSuccess = farmId => ({
  type: DELETE_FARM_SUCCESS,
  farmId
})

const deleteFarmFailure = payloadError => ({
  type: DELETE_FARM_FAILURE,
  error: payloadError
})


export const deleteFarm = farmId => {
  return dispatch => {
    dispatch(deleteFarmRequest())
    return deleteBaseApiHelper(`/farms/${farmId}`)
      .then(() => {
        return dispatch(deleteFarmSuccess(farmId))
      })
      .catch(rejected => {
        dispatch(deleteFarmFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}
