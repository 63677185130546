import styled, { css } from 'styled-components';
import { mobile } from '../common/utils/screenStyles';

export const StyledContainer = styled.div`
  font-family: Lato;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
	padding: 0;
  min-height: calc(100vh - (50px + 224px)); //navbar + footer
  & > * {
    width: 100%
  }

  @media screen and (min-width: 768px){
		padding: 0;
		${props => {
    if (props.overrideStyle) {
      return css`
					${{ ...props.overrideStyle }}
				`;
    }
  }}
  }

  @media screen and (min-width: 992px){
    min-height: calc(100vh - (66px + 80px)); //navbar + footer
	}

	${mobile`
		padding: 5px;
	`}
	.page-title{
		h1, h2, h3{
      font-family: Lato;
      font-weight: bold
			font-size: 24px;
			color: #6d6e71;
			margin-bottom: 10px;
			cursor: default;
		}
	}
  &.authenticated{
    align-items: flex-start;
  }
  &.fullscreen {
	height: 100vh;
	width: 100vw;
	position: absolute;
	background: #cccccc;
	top: 0;
	padding: 0px !important;
  }

	${props => {
    if (props.overrideStyle) {
      return css`
        ${{ ...props.overrideStyle }}
      `;
    }
  }}
`;

export const StyledWrapper = styled.div`
	&.fullscreen {
		height: 100vh;
		width: 100vw;
		position: absolute;
		background: #cccccc;
		top: 0;
		padding: 0px !important;
		z-index: 3
	}

	${props => {
    if (props.isAuthenticated) {
      return css`
				padding-left: 97px;
      `;
    }
  }}
	width: 100%;
	height: 100%;
`;
