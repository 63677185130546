import React from 'react'
import PropTypes from 'prop-types'
import corn from '../../assets/images/trace_corn.png'
import { StyledNotFoundPage } from './styles'
import { theme } from '../../common/utils/theme'

const ServerError = (props) => {
  return(
    <StyledNotFoundPage theme={theme}>
      <div height="60px">&nbsp;</div>
      <img src={corn} alt="Trace Genomics 500 Corn"/>
      <h1>500</h1>
      <h3>{`Sorry, something's gone wrong`}</h3>
      <p>{`Looks like a bad request has resulted in an error.`}</p>

      <button onClick={() => props.history.go(-2)}>Go Back</button>
    </StyledNotFoundPage>
  )
}

ServerError.propTypes = {
  history: PropTypes.shape({
    go: PropTypes.func,
  }).isRequired
}
export default ServerError
