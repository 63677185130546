import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import UserLinks from './UserLinks'
import logo from '../../assets/images/TraceGenomics_white.png'
import { logoutUser } from '../auth/auth-actions'
import {
  StyledSideNavBar,
  StyledSideNavBarNavItem,
  StyledAbsoluteStickyWrapper
} from './styles'
import {
  GrowerIcon,
  OrdersIcon,
  MapsIcon,
  AnalyticsIcon,
  FieldDiscoveryIcon,
} from '../../common/components/Icons'


const routes = [
  {
    to: '/growers',
    icon: GrowerIcon,
    title: 'Growers'
  },
  {
    to: '/orders',
    icon: OrdersIcon,
    title: 'Orders'
  },
  {
    to: '/farm-overview',
    icon: MapsIcon,
    title: 'Maps'
  },
  {
    to: '/analytics',
    icon: AnalyticsIcon,
    title: 'Analytics'
  },
  {
    to: '/field-discovery/rule-list',
    icon: FieldDiscoveryIcon,
    title: 'Field Discovery',
    unreleased: false,
    beta: false
  }
]


const MainNavLinks = ({ pathname, to, Icon, title }) => {
  return (
    <StyledSideNavBarNavItem>
      <NavLink to={to} className={(pathname === to) ? 'open' : ''}>
        <Icon style={{ height: '48px', width: '48px' }} />
        {title}
      </NavLink>
    </StyledSideNavBarNavItem>
  )
}

MainNavLinks.propTypes = {
  Icon: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const SideNavBar = (props) => {
  const { currentUser, dispatch, pathname, overrideStyle, unreleasedVisible, betaVisible } = props
  return (
    <StyledAbsoluteStickyWrapper >
      <StyledSideNavBar style={overrideStyle}>
        <div className="link-wrapper">
          <NavLink to="/" className="navbar-brand">
            <img src={logo} alt="Trace Genomics Logo" />
          </NavLink>
          <div className="link-container">
            {
              routes.map((route) => {
                if (!unreleasedVisible && route.unreleased) return (<div key={route.title}></div>)
                if (!betaVisible && route.beta) return (<div key={route.title}></div>)
                return (
                  <MainNavLinks
                    key={route.to}
                    pathname={pathname}
                    to={route.to}
                    Icon={route.icon}
                    title={route.title}
                  />
                )
              })
            }
          </div>
        </div>
        <UserLinks
          currentUser={currentUser}
          pathname={pathname}
          logOut={() => dispatch(logoutUser())}
        />
      </StyledSideNavBar>
    </StyledAbsoluteStickyWrapper>
  )
}

SideNavBar.propTypes = {
  currentUser: PropTypes.object,
  dispatch: PropTypes.func,
  pathname: PropTypes.string,
  overrideStyle: PropTypes.object,
  unreleasedVisible: PropTypes.bool,
  betaVisible: PropTypes.bool
}

export default SideNavBar

