import {
  REPORT_REQUEST,
  REPORT_SUCCESS,
  REPORT_FAILURE,
} from './reports-actions'


// Initial State
const initialState = {
  byOrderId: {},
}

export const reportsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case REPORT_REQUEST:
      return {
        ...state,
        byOrderId: {
          ...state.byOrderId,
          [action.orderId]: {
            ...state.byOrderId[action.orderId],
            [action.reportType]: {
              isFetching: true
            }
          }
        }
      }
    case REPORT_SUCCESS:
    case REPORT_FAILURE:
      return {
        ...state,
        byOrderId: {
          ...state.byOrderId,
          [action.orderId]: {
            ...state.byOrderId[action.orderId],
            [action.reportType]: {
              isFetching: false
            }
          }
        }
      }
    default: {
      return state
    }
  }
}

