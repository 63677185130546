import { getBaseApiHelper } from '../../common/utils/apiHelpers'

export const TAGS_REQUEST = 'TAGS_REQUEST'
export const TAGS_SUCCESS = 'TAGS_SUCCESS'
export const TAGS_FAILURE = 'TAGS_FAILURE'

const accountTagsRequest = () => ({ type: TAGS_REQUEST })
const accountTagsSuccess = (data) => ({ type: TAGS_SUCCESS, ...data })
const accountTagsFailure = (error) => ({ type: TAGS_FAILURE, error })

export const getAccountTags = (accountId) => {
  return dispatch => {
    dispatch(accountTagsRequest())
    return getBaseApiHelper(`/account/${accountId}/tags`)
      .then(response => {
        dispatch(accountTagsSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch(rejected => {
        dispatch(accountTagsFailure(rejected))
        return Promise.reject(rejected)
      })
  }
}

export const updateAccountTags = (data) => {
  return dispatch => {
    dispatch(accountTagsSuccess(data))
  }
}

