const derivedIndicators = {
  'phytophthora_ratio': {
    type: 'pathogen',
    crops: ['ALMOND'],
    flags: ['wilbur-ellis'],
    disease: null,
    display_unit: '',
    indicator_type: 'pathogen',
    key: 'phytophthora_ratio',
    name: 'Phytophthora Ratio',
    search_term: null,
    text: 'Ratio of Phytophthora Cinnamomi / Phytophthora spp.',
    calculation: (results) => results['Phytophthora cinnamomi'] / results['Phytophthora spp.']
  }
}

export const addIndicatorValues = (results, user) => {
  if (!results || !Object.values(results).length) return {}
  for (let ind in derivedIndicators) {
    if (derivedIndicators[ind].beta && !('allow-beta-external' in user.flags)) continue
    if (!derivedIndicators[ind].flags.every(flag=>flag in user.flags)) continue
    let calculatedValue = derivedIndicators[ind].calculation(results)
    results[ind] = typeof calculatedValue === 'number' && !Number.isNaN(calculatedValue) ? calculatedValue : undefined
  }
  return results
}

export const removeIndicatorValues = (results) => {
  if (!results || !Object.values(results).length) return {}
  for (let ind in derivedIndicators) {
    delete results[ind]
  }
  return results
}

export const addIndicators = (indicators, user) => {
  for (let ind in derivedIndicators) {
    let indDef = derivedIndicators[ind]
    if (indDef.beta && !('allow-beta-external' in user.flags)) continue
    if (!derivedIndicators[ind].flags.every(flag=>flag in user.flags)) continue
    indicators.allByKey[ind] = indDef
    if (indDef.type === 'pathogen') {
      indDef.crops.forEach(crop => {
        indicators.crop_pathogens[crop].pathogens[ind] = indDef
      })
    } else if (indDef.type === 'chemistry') {
      indicators.chemistry_parameters[ind] = indDef
    } else if (indDef.type === 'shi') {
      indicators.shis[ind] = indDef
    }
  }
  return indicators
}


export const removeIndicators = (indicators) => {
  for (let ind in derivedIndicators) {
    let indDef = derivedIndicators[ind]
    delete indicators.allByKey[ind]
    if (indDef.type === 'pathogen') {
      indDef.crops.forEach(crop => {
        delete indicators.crop_pathogens[crop].pathogens[ind]
      })
    } else if (indDef.type === 'chemistry') {
      delete indicators.chemistry_parameters[ind]
    } else if (indDef.type === 'shi') {
      delete indicators.shis[ind]
    }
  }
  return indicators
}
