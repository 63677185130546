import {
  getBaseApiHelper,
  postBaseApiHelper,
  patchBaseApiHelper,
  deleteBaseApiHelper,
  getAccountId
} from '../../common/utils/apiHelpers.js'
import { addFlashMessage } from '../../components/FlashMessages/messages-actions.js'
import { getAccountFarms } from './farmActions'
import { getSamples } from './samples-actions.js'
import { appStore } from '../../index'

export const GET_ACCOUNT_FIELDS_REQUEST = 'GET_ACCOUNT_FIELDS_REQUEST'
export const GET_ACCOUNT_FIELDS_FAILURE = 'GET_ACCOUNT_FIELDS_FAILURE'
export const GET_ACCOUNT_FIELDS_SUCCESS = 'GET_ACCOUNT_FIELDS_SUCCESS'
export const GET_FIELD_REQUEST = 'GET_FIELD_REQUEST'
export const GET_FIELD_FAILURE = 'GET_FIELD_FAILURE'
export const GET_FIELD_SUCCESS = 'GET_FIELD_SUCCESS'
export const SELECT_FIELD_FOR_DETAIL = 'SELECT_FIELD_FOR_DETAIL'
export const SET_ALL_FIELDS_RETRIEVED = 'SET_ALL_FIELDS_RETRIEVED'

export const FIELD_POST_REQUEST = 'FIELD_POST_REQUEST'
export const FIELD_POST_SUCCESS = 'FIELD_POST_SUCCESS'
export const FIELD_POST_FAILURE = 'FIELD_POST_FAILURE'

export const FIELD_PATCH_REQUEST = 'FIELD_PATCH_REQUEST'
export const FIELD_PATCH_SUCCESS = 'FIELD_PATCH_SUCCESS'
export const FIELD_PATCH_FAILURE = 'FIELD_PATCH_FAILURE'

export const DELETE_FIELD_REQUEST = 'DELETE_FIELD_REQUEST'
export const DELETE_FIELD_SUCCESS = 'DELETE_FIELD_SUCCESS'
export const DELETE_FIELD_FAILURE = 'DELETE_FIELD_FAILURE'

export const FIELDS_POST_REQUEST = 'FIELDS_POST_REQUEST'
export const FIELDS_POST_SUCCESS = 'FIELDS_POST_SUCCESS'
export const FIELDS_POST_FAILURE = 'FIELDS_POST_FAILURE'

export const GET_FIELD_MAP_REQUEST = 'GET_FIELD_MAP_REQUEST'
export const GET_FIELD_MAP_SUCCESS = 'GET_FIELD_MAP_SUCCESS'
export const GET_FIELD_MAP_FAILURE = 'GET_FIELD_MAP_FAILURE'

export const ZONE_POST_SUCCESS = 'ZONE_POST_SUCCESS'


export const MERGE_FIELDS_REQUEST = 'MERGE_FIELDS_REQUEST'
export const MERGE_FIELDS_SUCCESS = 'MERGE_FIELDS_SUCCESS'
export const MERGE_FIELDS_FAILURE = 'MERGE_FIELDS_FAILURE'

// Action Creators

export const selectFieldForDetail = fieldId => ({
  type: SELECT_FIELD_FOR_DETAIL,
  fieldId
})

const getAccountFieldsRequest = (accountId) => ({
  type: GET_ACCOUNT_FIELDS_REQUEST,
  accountId
})

const getAccountFieldsSuccess = (payload, accountId) => ({
  type: GET_ACCOUNT_FIELDS_SUCCESS,
  payload,
  accountId
})

const getAccountFieldsFailure = (payloadError, accountId) => ({
  type: GET_ACCOUNT_FIELDS_FAILURE,
  error: payloadError,
  accountId
})

export const getAccountFields = (accountId, useSelectedAccounts=false) => {
  if (useSelectedAccounts && !accountId) {
    const store = appStore.getState()
    accountId = store.auth.selectedAccounts?.[0] || getAccountId()
  } else {
    accountId = accountId || getAccountId()
  }
  return dispatch => {
    dispatch(getAccountFieldsRequest(accountId))
    return getBaseApiHelper(`/account/${accountId}/fields`)
      .then(response => {
        dispatch(getAccountFieldsSuccess(response.data, accountId))
      })
      .catch(rejected => {
        dispatch(getAccountFieldsFailure(rejected.response, accountId))
        return Promise.reject({ error: rejected.response })
      })
  }
}

const getFieldRequest = () => ({
  type: GET_FIELD_REQUEST,
  isFetching: true
})

const getFieldSuccess = (payload) => ({
  type: GET_FIELD_SUCCESS,
  payload,
})

const getFieldFailure = payloadError => ({
  type: GET_FIELD_FAILURE,
  error: payloadError
})

export const getField = (fieldId) => {
  return dispatch => {
    dispatch(getFieldRequest())
    return getBaseApiHelper(`/fields/${fieldId}`)
      .then(response => {
        dispatch(getFieldSuccess(response.data))
        return response.data
      })
      .catch(rejected => {
        dispatch(getFieldFailure(rejected.response))
        return Promise.reject(rejected.response)
      })
  }
}


const fieldPostRequest = () => ({
  type: FIELD_POST_REQUEST,
  isFetching: true
})

const fieldPostSuccess = payload => ({
  type: FIELD_POST_SUCCESS,
  payload
})

const fieldsPostRequest = () => ({
  type: FIELDS_POST_REQUEST,
  isFetching: true
})

const fieldsPostSuccess = payload => ({
  type: FIELDS_POST_SUCCESS,
  payload
})

const fieldsPostFailure = payloadError => ({
  type: FIELDS_POST_FAILURE,
  error: payloadError
})

const fieldPostFailure = payloadError => ({
  type: FIELD_POST_FAILURE,
  error: payloadError
})

const zonePostSuccess = (accountId, fieldId, payload) => ({
  type: ZONE_POST_SUCCESS,
  payload,
  accountId,
  fieldId,
})

const fieldPatchRequest = () => ({
  type: FIELD_PATCH_REQUEST,
  isFetching: true
})

const fieldPatchSuccess = payload => ({
  type: FIELD_PATCH_SUCCESS,
  payload
})

const fieldPatchFailure = payloadError => ({
  type: FIELD_PATCH_FAILURE,
  error: payloadError
})

const addBoundaries = async (field_id, boundaryForm, dispatch) => {
  dispatch(fieldPatchRequest())
  try {
    const response = await patchBaseApiHelper(`/fields/${field_id}/shape`, boundaryForm)
    dispatch(fieldPatchSuccess(response.data))
    return response
  }
  catch (rejected) {
    let message = rejected.response?.status < 500
      ? rejected.response.data
      : 'Unable to load shapefile, draw boundaries or contact customer service for help'
    dispatch(fieldPatchFailure(rejected.response))
    dispatch(addFlashMessage({
      type: 'danger',
      title: `Upload Error For Boundary`,
      text: message
    }))
  }
}

const addZone = async (field_id, accountId, zoneForm, dispatch) => {
  dispatch(fieldPostRequest())
  try {
    const response = await postBaseApiHelper(`/fields/${field_id}/zones`, zoneForm)
    dispatch(zonePostSuccess(accountId, field_id, response.data))
    return response
  }
  catch (rejected) {
    dispatch(fieldPostFailure(rejected.response))
    let message = rejected.response?.status < 500
      ? rejected.response.data
      : 'Unable to load zones, contact customer service for help'
    dispatch(addFlashMessage({
      type: 'danger',
      title: `Upload Error For Zone`,
      text: message
    }))
  }
}

export const createField = ({
  name, account_id, boundary, current_crop, previous_crop, farm_id, boundaryForm, zoneForm
}) => {
  return dispatch => {
    dispatch(fieldPostRequest())
    const payload = {
      name,
      account_id,
      farm_id,
      current_crop,
      previous_crop
    }
    if (boundary) payload.boundary = boundary
    return postBaseApiHelper('/fields', payload)
      .then(response => {
        dispatch(fieldPostSuccess(response.data))
        let fieldId = response.data.id
        if (boundaryForm) {
          addBoundaries(fieldId, boundaryForm, dispatch)
        }
        if (zoneForm) {
          addZone(fieldId, account_id, zoneForm, dispatch)
            .then(() => {
              //if no boundary exists, create the boundary from the zones
              if (!boundaryForm && !boundary) {
                addBoundaries(fieldId, zoneForm, dispatch)
              }
            })
        }
        return response.data
      })
      .catch(rejected => {
        dispatch(fieldPostFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

export const createFields = (account_id, payloads) => {
  return dispatch => {
    dispatch(fieldsPostRequest())
    const createField = payload => postBaseApiHelper('/fields', payload)
    return Promise.all(payloads.map(createField))
      .then(responses => {
        return dispatch(fieldsPostSuccess(responses.map(res => res.data)))
      })
      .catch(rejected => {
        dispatch(fieldsPostFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}


export const updateField = (fieldId, {
  name,
  account_id,
  boundary,
  current_crop,
  previous_crop,
  farm_id,
  boundaryForm,
  zoneForm,
  deleteZone
}) => {
  return dispatch => {
    dispatch(fieldPatchRequest())
    const payload = {
      name,
      account_id,
      farm_id,
      current_crop,
      previous_crop
    }
    if (boundary) payload.boundary = boundary
    if (deleteZone === true) payload.zones = null
    return patchBaseApiHelper(`/fields/${fieldId}`, payload)
      .then(response => {
        if (boundaryForm) {
          addBoundaries(response.data.id, boundaryForm, dispatch)
        }
        if (zoneForm) {
          addZone(response.data.id, account_id, zoneForm, dispatch)
            .then(() => {
              //if no boundary exists, create the boundary from the zones
              if (!boundaryForm && !boundary) {
                addBoundaries(fieldId, zoneForm, dispatch)
              }
            })
        }
        return dispatch(fieldPatchSuccess(response.data))
      })
      .catch(rejected => {
        dispatch(fieldPatchFailure(rejected?.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}



const deleteFieldRequest = () => {
  return {
    type: DELETE_FIELD_REQUEST,
    isFetching: true
  }
}

const deleteFieldSuccess = (fieldId, accountId) => ({
  type: DELETE_FIELD_SUCCESS,
  fieldId,
  accountId
})

const deleteFieldFailure = payloadError => ({
  type: DELETE_FIELD_FAILURE,
  error: payloadError
})

export const deleteField = (fieldId, accountId) => {
  return dispatch => {
    dispatch(deleteFieldRequest())
    return deleteBaseApiHelper(`/fields/${fieldId}`)
      .then(() => {
        return dispatch(deleteFieldSuccess(fieldId, accountId))
      })
      .catch(rejected => {
        dispatch(deleteFieldFailure(rejected?.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

const getFieldMapRequest = () => ({
  type: 'GET_FIELD_MAP_REQUEST'
})

const getFieldMapSuccess = (field, key, payload) => ({
  type: 'GET_FIELD_MAP_SUCCESS',
  fieldId: field,
  key,
  payload
})

const getFieldMapFailure = (errorPayload) => ({
  type: 'GET_FIELD_MAP_FAILURE',
  error: errorPayload
})

export const getFieldMap = (fieldId, crop, indicator, startDate, endDate) => {
  return dispatch => {
    const key = crop + indicator + startDate + endDate
    dispatch(getFieldMapRequest())
    return getBaseApiHelper(`/maps` +
      `?field=${fieldId}` +
      `&crop=${crop}` +
      `&indicator=${indicator}` +
      `&start=${startDate}` +
      `&end=${endDate}`
    )
      .then((payload) => {
        return dispatch(getFieldMapSuccess(fieldId, key, payload))
      })
      .catch(rejected => {
        dispatch(getFieldMapFailure(rejected?.response))
      })
  }
}

const mergeFieldsRequest = () => ({
  type: 'MERGE_FIELDS_REQUEST'
})

const mergeFieldsSuccess = () => ({
  type: 'MERGE_FIELDS_SUCCESS',
})

const mergeFieldsFailure = (errorPayload) => ({
  type: 'MERGE_FIELDS_FAILURE',
  error: errorPayload
})
export const mergeFields = (fieldId, mergeIds, accountId) => {
  if (!accountId) {
    accountId = getAccountId()
  }
  return dispatch => {
    dispatch(mergeFieldsRequest())
    return patchBaseApiHelper(`/fields/${fieldId}/merge`, { merge_ids: mergeIds })
      .then(() => {
        dispatch(mergeFieldsSuccess())
        return Promise.all(
          dispatch(getAccountFields(accountId)),
          dispatch(getAccountFarms(accountId)),
          dispatch(getSamples(accountId,undefined,true))
        )
      })
      .catch(rejected => {
        dispatch(mergeFieldsFailure(rejected?.response))
        return Promise.reject(rejected?.response)
      })
  }
}

export const setAllFieldsRetrieved = () => {
  return {
    type: SET_ALL_FIELDS_RETRIEVED
  }
}
