import {
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  CREATE_ORDERS_REQUEST,
  CREATE_ORDERS_SUCCESS,
  CREATE_ORDERS_FAILURE,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_FAILURE,
  ORDER_SAMPLE_POST_REQUEST,
  ORDER_SAMPLE_POST_SUCCESS,
  ORDER_SAMPLE_POST_FAILURE,
  MULTIPLE_ORDER_SAMPLES_POST_REQUEST,
  MULTIPLE_ORDER_SAMPLES_POST_SUCCESS,
  MULTIPLE_ORDER_SAMPLES_POST_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  RESET_SERVER_ERROR,
  SET_SELECTED_PAGE
} from './orders-actions'

import {
  DELETE_SAMPLE_REQUEST,
  DELETE_SAMPLE_SUCCESS,
  DELETE_SAMPLE_FAILURE,
  SAMPLE_PATCH_SUCCESS
} from './samples-actions'

import {
  EDIT_GROWER_SUCCESS
} from './permissionsActions'

import { getSampleNameAndDepthKey } from '../../components/Orders/helpers'

const selectedPageKey = `orderListSelectedPage-${sessionStorage.getItem('userId')}`

const initialState = {
  pending: false,
  pendingPages: new Set(),
  serverError: undefined,
  sampleErrors: [],
  byId: {},
  byPage: {},
  pageCount: 0,
  allIds: [],
  selectedPage: localStorage.getItem(selectedPageKey)
    ? Number(localStorage.getItem(selectedPageKey))
    : 1
}

export const ordersReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case ORDERS_REQUEST: {
      state.pendingPages.add(action.page)
      return {
        ...state,
        pending: true,
        serverError: undefined
      }
    }

    case ORDERS_SUCCESS: {
      state.pendingPages.delete(action.page)
      let byId = {}
      let allIds = []
      action.orders.forEach(order => {
        byId[order.id] = order
        allIds.push(order.id)
      })
      const orderIds = action.orders.map(o => o.id)
      return {
        ...state,
        pending: false,
        serverError: undefined,
        byId: { ...state.byId, ...byId },
        allIds: [ ...state.allIds, ...allIds ],
        byPage: action.hasNewFilters
          ? { [action.meta.page]: orderIds }
          : { ...state.byPage, [action.meta.page]: orderIds },
        pageCount: action.meta.page_count
      }
    }

    case ORDERS_FAILURE: {
      state.pendingPages.delete(action.page)
      return { ...state, pending: false, serverError: action.error }
    }

    case CREATE_ORDER_REQUEST: {
      return { ...state, pending: true, serverError: undefined }
    }

    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        pending: false,
        serverError: undefined,
        byId: {
          ...state.byId,
          [action.order.id]: action.order
        },
        allIds: [...state.allIds, action.order.id],
        byPage: {}
      }
    }

    case CREATE_ORDER_FAILURE: {
      return { ...state, pending: false, serverError: action.error }
    }

    case CREATE_ORDERS_REQUEST: {
      return { ...state, pending: true, serverError: undefined }
    }

    case CREATE_ORDERS_SUCCESS: {
      return {
        ...state,
        pending: false,
        serverError: undefined
      }
    }

    case CREATE_ORDERS_FAILURE: {
      return { ...state, pending: false, serverError: action.error }
    }

    case ORDER_REQUEST: {
      return { ...state, pending: true, serverError: undefined }
    }

    case ORDER_SUCCESS: {
      return {
        ...state,
        pending: false,
        serverError: undefined,
        byId: {
          ...state.byId,
          [action.order.id]: action.order
        },
        allIds: [...state.allIds, action.order.id]
      }
    }

    case ORDER_FAILURE: {
      return { ...state, pending: false, serverError: action.error }
    }

    case ORDER_SAMPLE_POST_REQUEST: {
      return { ...state, pending: true, serverError: undefined }
    }

    case SAMPLE_PATCH_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.sample.order_id]: {
            ...state.byId[action.sample.order_id],
            samples: state.byId[action.sample.order_id].samples.map(sample => sample.id === action.sample?.id ? action.sample : sample)
          }
        }
      }
    }

    case ORDER_SAMPLE_POST_SUCCESS: {
      let samplesByNameAndDepth = state.byId[action.orderId].samples.reduce((acc, currSample) => {
        acc[getSampleNameAndDepthKey(currSample)] = currSample
        return acc
      }, {})
      // new name and depth -> new sample, same name and depth -> updated sample
      action.samples.forEach(sample => { samplesByNameAndDepth[getSampleNameAndDepthKey(sample)] = sample })
      return {
        ...state,
        pending: false,
        serverError: undefined,
        sampleErrors: action.sampleErrors,
        byId: {
          ...state.byId,
          [action.orderId]: {
            ...state.byId[action.orderId],
            samples: Object.values(samplesByNameAndDepth)
          }
        }
      }
    }

    case ORDER_SAMPLE_POST_FAILURE: {
      return { ...state, pending: false, serverError: action.error }
    }

    case MULTIPLE_ORDER_SAMPLES_POST_REQUEST: {
      return { ...state, pending: true, serverError: null }
    }

    case MULTIPLE_ORDER_SAMPLES_POST_SUCCESS: {
      return { ...initialState }
    }

    case MULTIPLE_ORDER_SAMPLES_POST_FAILURE: {
      return { ...state, pending: false, serverError: action.error }
    }


    case DELETE_SAMPLE_REQUEST:
    case DELETE_ORDER_REQUEST: {
      return { ...state, pending: true, serverError: null }
    }

    case DELETE_ORDER_SUCCESS: {
      let byId = { ...state.byId }
      delete byId[action.orderId]
      let idx = state.allIds.indexOf(action.orderId)
      let allIds = state.allIds.splice(idx, 1)
      return {
        ...state,
        pending: false,
        serverError: null,
        byId,
        allIds,
        byPage: {}
      }
    }

    case DELETE_SAMPLE_SUCCESS: {
      const byPage = { ...state.byPage }
      const allIds = state.allIds.filter(orderId => !(byPage[state.selectedPage] || []).includes(orderId))
      delete byPage[state.selectedPage]
      return {
        ...state,
        pending: false,
        serverError: null,
        byId: {
          [action.sample.order_id]: {
            ...state.byId[action.sample.order_id],
            samples: state.byId[action.sample.order_id].samples.filter(sample => sample.id !== action.sample.id)
          }
        },
        allIds,
        byPage
      }
    }

    case DELETE_SAMPLE_FAILURE:
    case DELETE_ORDER_FAILURE: {
      return { ...state, pending: false, serverError: action.error }
    }

    case RESET_SERVER_ERROR: {
      return { ...state, serverError: null }
    }

    case SET_SELECTED_PAGE: {
      localStorage.setItem(selectedPageKey, action.selectedPage)
      return { ...state, selectedPage: action.selectedPage }
    }

    case EDIT_GROWER_SUCCESS: {
      const { permission } = action
      const byId = state.byId
      Object.values(byId).forEach(order=>{
        if (order.grower_account_id === permission.owning_account_id){
          order.grower_name = permission.account_name
        }
      })
      return {
        ...state,
        byId
      }
    }

    default: {
      return state
    }
  }
}
