import { css } from 'styled-components'

/**
 * Media query template that renders only on smallmobile sizes.
 * Use like:
 *   ${smallMobile`margin: 0;`}
 */
export function smallMobile(...args) {
  return css`
    @media screen and (max-width: 500px) {
      ${css(...args)};
    }
  `
}

/**
 * Media query template that renders only on mobile sizes.
 * Use like:
 *   ${mobile`padding: 0;`}
 */
export function mobile(...args) {
  return css`
    @media screen and (max-width: 760px) {
      ${css(...args)};
    }
  `
}

/**
 * Media query template that renders only on mobile sizes.
 * Use like:
 *   ${tablet`padding: 0;`}
 */
export function tablet(...args) {
  return css`
    @media screen and (min-width: 761px) and (max-width: 1024px) {
      ${css(...args)};
    }
  `
}

/**
 * Media query template that renders only on desktop sizes.
 * Use like:
 *   ${desktop`height: 0;`}
 */
export function desktop(...args) {
  return css`
    @media (min-width: 1025px) {
      ${css(...args)};
    }
  `
}

/**
 * Media query template that renders only on desktop sizes.
 * Use like:
 *   ${highResScreen`margin: 0;`}
 */
export function highResScreen(...args) {
  return css`
    @media (min-width: 1921px) {
      ${css(...args)};
    }
  `
}
