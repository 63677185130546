import { getBaseApiHelper } from '../../common/utils/apiHelpers'

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST'
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS'
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE'

/**
Get products
**/
const getProductsRequest = () => ({ type: PRODUCTS_REQUEST })
const getProductsSuccess = products => ({ type: PRODUCTS_SUCCESS, products })
const getProductsFailure = error => ({ type: PRODUCTS_FAILURE, error })

export function getProducts() {
  return dispatch => {
    dispatch(getProductsRequest())
    return getBaseApiHelper(`/products`)
      .then(resp => {
        dispatch(getProductsSuccess(resp.data))
        return resp.data
      })
      .catch(error => {
        dispatch(getProductsFailure(error))
        return Promise.reject(error)
      })
  }
}
