import { createSelector } from 'reselect'

import {
  SAMPLES_REQUEST,
  SAMPLES_SUCCESS,
  SAMPLES_FAILURE,
  DRAG_MARKER,
  DROP_MARKER,
  SET_ACTIVE_SAMPLEMARKER,
  RESET_MARKERS,
  DELETE_SAMPLE_REQUEST,
  DELETE_SAMPLE_SUCCESS,
  DELETE_SAMPLE_FAILURE,
  SAMPLE_PATCH_REQUEST,
  SAMPLE_PATCH_SUCCESS,
  SAMPLE_PATCH_FAILURE
} from './samples-actions'
import {
  ORDER_SAMPLE_POST_SUCCESS,
  ORDER_SUCCESS
} from './orders-actions'

import {
  ADD_DERIVED_INDICATORS,
  REMOVE_DERIVED_INDICATORS
} from './indicator-actions'
import { getAccountId } from '../utils/apiHelpers'
import { addIndicatorValues, removeIndicatorValues } from '../utils/indicatorHelpers'
import {appStore} from '../../index'
import { getSelectedAccountNamesById } from '../../components/auth/auth-reducer'


// Initial Samples State
const initialState = {
  isFetching: false,
  pendingAccounts: new Set(),
  isPosting: false,
  errorMessage: null,
  samplesByAccount: {},
  ui: {
    markers: [],
  }
}

// Samples Reducer
function samplesReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case SAMPLES_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        pendingAccounts: state.pendingAccounts.add(action.accountId)
      })
    }
    case SAMPLES_SUCCESS: {
      state.pendingAccounts.delete(action.accountId)
      if (action.useCache) {
        return {
          ...state,
          ui: { ...state.ui, markers: [...state.ui.markers] }
        }
      }
      const acct = action.accountId
      const byId = {}
      const allIds = []
      const {account_id, account_name} = appStore.getState().auth.user
      const growersById = { ...getSelectedAccountNamesById(), [account_id]: account_name }
      action.samples.forEach(sample => {
        for (var property in sample) {
          sample[property] = sample[property] === null ? '' : sample[property]
        }
        sample['has_chem_pending'] = sample.include_chem && !sample.chem_processed_date
        sample['has_bio_pending'] = sample.include_bio && !sample.processed_date && !sample.processing_final_failure_date

        sample.results = {...sample.quantities, ...sample.chemistry_results}
        byId[sample.id] = { ...sample, grower: growersById?.[sample.account_id] }
        allIds.push(sample.id)
      })
      return {
        ...state,
        isFetching: !!state.pendingAccounts.size,
        samplesByAccount: {
          ...state.samplesByAccount,
          [acct]: {byId, allIds}
        },
        ui: { ...state.ui, markers: [...state.ui.markers] }
      }
    }
    case SAMPLES_FAILURE: {
      state.pendingAccounts.delete(action.accountId)
      return {
        ...state,
        isFetching: !!state.pendingAccounts.size,
        errorMessage: action.error
      }
    }
    case DRAG_MARKER: {
      /*
        expect action.sampleId to be a string
        if this is a new sample,
          expect there to be no marker where marker.sampleId === action.sampleId
          set all markers to active === false
          then push a new new marker with sampleId = action.sampleId and active = true
          the new marker should be the only marker where active === true
        if this is an existing sample,
          expect there to be a marker with marker.sampleId === action.sampleId
          set that one to true and expect it is the only marker where active === true
      */
      var newMarkers = state.ui.markers.map((currentMarker) => {
        if (currentMarker.sampleId?.toString() === action.sampleId?.toString()) {
          currentMarker.active = true
        } else {
          currentMarker.active = false
        }
        return currentMarker
      })

      if (newMarkers.every(marker => {
        return marker.active === false
      }
      )) {
        newMarkers.push({ sampleId: action.sampleId, active: true })
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          markers: newMarkers
        }
      }
    }
    case DROP_MARKER: {
      const newMarkers = state.ui.markers.filter((m) => {
        return m.sampleId !== action.marker.sampleId
      })
        .map((marker) => {
          marker['active'] = false
          return marker
        })
        .concat(action.marker)
      /*
        expect newMarkers to have an entry === to action.marker
        expect all markers in newMarkers to have id, latitude, and lognitude
      */
      return {
        ...state,
        ui: {
          ...state.ui,
          markers: newMarkers
        }
      }
    }
    case SET_ACTIVE_SAMPLEMARKER: {
      var newmarkers = state.ui.markers.map(m => {
        m.active = (m.sampleId === action.sampleId) ? m.active = true : m.active = false
        return m
      })

      return {
        ...state,
        ui: {
          ...state.ui,
          markers: newmarkers,
        }
      }
    }
    case RESET_MARKERS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          markers: state.ui.markers
            .filter(m => m.sampleId)
            .map(m => m.sampleId === action.sample?.id
              ? { ...m, active: false, latitude: action.sample.latitude, longitude: action.sample.longitude }
              : { ...m, active: false })
        }
      }
    }
    case DELETE_SAMPLE_REQUEST: {
      return { ...state, isPosting: true, errorMessage: null }
    }
    case DELETE_SAMPLE_SUCCESS: {
      const acctSamples = state.samplesByAccount[action.sample.account_id]
      if (acctSamples?.byId) {
        delete acctSamples.byId[action.sample.id]
      }
      return {
        ...state,
        samplesByAccount: acctSamples?.byId
          ? {
            ...state.samplesByAccount,
            [action.sample.account_id] : {
              byId: { ...acctSamples.byId },
              allIds: acctSamples.allIds.filter(item => item !== action.sample.id)
            }
          }
          : state.samplesByAccount,
        ui: {
          ...state.ui,
          markers: state.ui.markers.filter(m => m.sampleId !== action.sample.id)
        },
        isPosting: false
      }
    }
    case DELETE_SAMPLE_FAILURE: {
      let msgString = null
      switch (action.error.status) {
        case 401:
          msgString = '401'
          //msgString = action.error.data.message
          break
        case 400:
          msgString = '400'
          //msgString = action.error.data
          break
        case 409:
          msgString = action.error.data
          break
        default:
          msgString = ''
          break
      }
      return { ...state, isPosting: false, errorMessage: msgString }
    }
    case SAMPLE_PATCH_REQUEST: {
      return { ...state, isPosting: true, errorMessage: null }
    }
    case SAMPLE_PATCH_SUCCESS: {
      const updatedMarkers = state.ui.markers.map(m => m.sampleId === action.sample.id
        ? {
          active: false,
          sampleId: action.sample.id,
          sampleName: action.sample.sample_name,
          latitude: action.sample.latitude,
          longitude: action.sample.longitude
        }
        : m)
      const accountId = action.sample?.account_id
      //see note about delaying store update in ORDER_SAMPLE_POST_SUCCESS
      let existingAcctSamples = state.samplesByAccount[accountId]
      let sample = action.sample
      for (var property in sample) {
        sample[property] = sample[property] === null ? '' : sample[property]
      }
      sample.results = {...sample.quantities, ...sample.chemistry_results}

      return !existingAcctSamples
        ? {
          ...state,
          ui: {
            ...state.ui,
            markers: updatedMarkers
          },
          isPosting: false
        }
        : {
          ...state,
          ui: {
            ...state.ui,
            markers: updatedMarkers
          },
          isPosting: false,
          samplesByAccount: {
            ...state.samplesByAccount,
            [accountId]: {
              ...state.samplesByAccount[accountId],
              byId: {
                ...existingAcctSamples.byId,
                [sample.id]: sample
              }
            }
          }
        }
    }
    case SAMPLE_PATCH_FAILURE: {
      return { ...state, isPosting: false, errorMessage: action.error }
    }

    // order reducers
    case ORDER_SAMPLE_POST_SUCCESS: {
      // new sample added from table
      const updatedMarkers = state.ui.markers.find(m => m.active)?.sampleId
        ? state.ui.markers
        // handle new sample with marker dropped onto map
        : state.ui.markers.map(m => m.sampleId ? m : { ...m, sampleId: action.samples[0]?.id, sampleName: action.samples[0]?.sample_name })
      // handle new sample where marker has NOT been dropped onto map
      if (!updatedMarkers.map(m => m.sampleId)?.includes(action.samples[0]?.id)) {
        updatedMarkers.push({ sampleId: action.samples[0]?.id, active: false })
      }
      const accountId = action.samples[0]?.account_id
      //only update store if we have already fetched samples for the user, else wait for a page to fetch all samples.
      //If we pull samples ONLY from orders, then users will not be able to see samples ordered by other users
      let existingAcctSamples = state.samplesByAccount[accountId]
      if (!existingAcctSamples) {
        return {
          ...state,
          ui: {
            ...state.ui,
            markers: updatedMarkers
          }
        }
      }
      const byId = { ...existingAcctSamples.byId}
      action.deletions.forEach(id => delete byId[id])
      const allIds = [...existingAcctSamples.allIds].filter(id => action.deletions.indexOf(id) === -1)
      action.samples.forEach(sample => {
        for (var property in sample) {
          sample[property] = sample[property] === null ? '' : sample[property]
        }
        byId[sample.id] = {...byId[sample.id], ...sample}
        allIds.indexOf(sample.id) === -1 && allIds.push(sample.id)
      })
      return {
        ...state,
        ui: {
          ...state.ui,
          markers: updatedMarkers
        },
        samplesByAccount: {
          ...state.samplesByAccount,
          [accountId]: {
            allIds: allIds,
            byId: byId
          }
        }
      }
    }

    case ORDER_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          markers: action.order.samples.map(sample => ({
            active: false,
            sampleId: sample.id,
            sampleName: sample.sample_name,
            latitude: sample.latitude,
            longitude: sample.longitude
          }))
        }
      }
    }
    case ADD_DERIVED_INDICATORS: {
      const globalState = appStore.getState()
      let a = action.accountId || getSelectedId(globalState)
      let samples = state.samplesByAccount?.[a]?.byId
      if (!samples) return state
      samples = {...samples}
      for (let id in samples){
        samples[id].results = addIndicatorValues(samples[id].results, globalState.auth.user)
      }
      return {
        ...state,
        samplesByAccount: {
          ...state.samplesByAccount,
          [a]: {
            ...state.samplesByAccount[a],
            byId: samples
          }
        }
      }
    }
    case REMOVE_DERIVED_INDICATORS: {
      const globalState = appStore.getState()
      let myid = action.accountId || getSelectedId(globalState)
      let mysamples = state.samplesByAccount?.[myid]?.byId
      if (!mysamples) return state
      mysamples = {...mysamples}
      for (let sample in mysamples){
        mysamples[sample].results = removeIndicatorValues(mysamples[sample].results, globalState.meta.betaVisible)
      }
      return {
        ...state,
        samplesByAccount: {
          ...state.samplesByAccount,
          [myid]: {
            ...state.samplesByAccount[myid],
            byId: mysamples
          }
        }
      }
    }

    default: {
      return state
    }
  }
}

export default samplesReducer

export function getAssociatedMarker(store, sampleId) {
  const found = store.entities.samples.ui.markers.find(m => m.sampleId === sampleId)
  return found ? found : { latitude: null, longitude: null }
}

export function getActiveSampleForm(store) {
  return store.entities.samples.ui.activeSampleForm
}

export function getMarkers(store) {
  return store.entities.samples.ui.markers
}


const getSelectedId = (store) => store.auth.selectedAccounts?.[0] || getAccountId()
const getSelectedIds = (store) => store.auth.selectedAccounts
const getSamplesById = samples => samples.byId

const getSampleEntities = (store) => store.entities.samples.samplesByAccount
const getSamplesByAccount = (id, byAcct) => {
  // must return strings instead of null/undefined to allow length checks and invariant violation
  return byAcct[id] ? byAcct[id] : {byId: '', allIds: ''}
}
export const samplesForAccount = createSelector(
  getSelectedId,
  getSampleEntities,
  getSamplesByAccount
)
export const getAccountSamplesById = createSelector(
  samplesForAccount,
  getSamplesById
)

const getSamplesForSelectedAccounts = (ids, byAcct) => {
  return ids.reduce((acc, id) => {
    if (byAcct[id]) {
      acc = {
        allIds: acc.allIds.concat(byAcct[id].allIds),
        byId: { ...acc.byId, ...byAcct[id].byId }
      }
    }
    return acc
  }, { allIds: [], byId: {} })
}
export const samplesForAccounts = createSelector(
  getSelectedIds,
  getSampleEntities,
  getSamplesForSelectedAccounts
)
export const getSelectedAccountSamplesById = createSelector(
  samplesForAccounts,
  getSamplesById
)
