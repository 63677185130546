export const ADD_DERIVED_INDICATORS = 'ADD_DERIVED_INDICATORS'
export const REMOVE_DERIVED_INDICATORS = 'REMOVE_DERIVED_INDICATORS'


export const addDerivedIndicators = (accountId) => {
  return {
    type: ADD_DERIVED_INDICATORS,
    accountId
  }
}

export const removeDerivedIndicators = (accountId) => {
  return {
    type: REMOVE_DERIVED_INDICATORS,
    accountId
  }
}
