import React from 'react'

/*******************
 DATE HELPERS
********************/

/**
 * @param {string} utcDateString A string of format parsable by MDN Date constructor
 * @returns {string} A string in format M/D/YY
 *
 * example input, output:
 * In: '2018-01-29T09:00:00+00:00'
 * Out: '1/29/18'
 */

export const utcToUSDate = (utcDateString, options={}) => {
  let date = new Date(utcDateString)
  let dateOptions = { month: 'numeric', day: 'numeric', year: options.yearType || '2-digit' }
  let usDate = date.toLocaleDateString('en-US', dateOptions)
  return usDate
}

/**
 * @param {string} unicode A string that is intended to break on `\n`
 * @returns {string}
 *
 * example input, output:
 * In: 'Hello\nWorld'
 * Out:
    Hello
    World
 */
export const formatMultilineUnicode = unicode => {
  if (!unicode) return ''
  return unicode.split('\n').map((str, key) => {
    return <span key={key}>{str}<br /></span>
  })
}


/**
 * @param {string} date A js Date obj
 * @returns {string} A string in format YYY-MM-DD
 *
 * example input, output:
 * In: new Date(2018-01-29T09:00:00+00:00)
 * Out: '2018-01-29'
 */
export const jsDateToSkewer = (date) => {
  if (date instanceof Date) {
    return date.toISOString().split('T')[0]
  }
}

/**
 * @param {string} date A year-first skewer case date (YYYY-MM-DD)
 * @returns {string} A string in format MM-DD-YY
 */

export const yearFirstSkewerCaseToUS = (date) => {
  if (!date) return ''
  const dateArray = date.split('-')
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0].slice(2)}`
}

/**
 * @param {string} date A string in format MM/DD/YY
 * @returns {string} A year-first skewer case date (YYYY-MM-DD)
 */
export const usToSkewer = (date) => {
  if (!date) return ''
  const dateArray = date.split('/')
  const year = dateArray[2] < 30 ? '20' + dateArray[2] : '19' + dateArray[2] //gotta change this line in 2031 :)
  return `${year}-${dateArray[0]}-${dateArray[1]}`
}

/**
 *
 * @returns {string} A string in format MM-DD-YY that is today's date
 */
export const getToday = () => {
  let date = new Date()
  let dateOptions = { month: 'numeric', day: 'numeric', year: '2-digit' }
  let usDate = date.toLocaleDateString('en-US', dateOptions)
  return usDate
}

/**
 *
 * @returns {string} A string in format "${num} days ago" from today
 */
export const getTimeDiff = (date) => {
  const durations = {
    'day': 60 * 60 * 24,  // seconds * minutes * hours
    'week': 60 * 60 * 24 * 7,  // days * 7
    'month': 60 * 60 * 24 * 7 * 4,  // weeks * 4
    'year': 60 * 60 * 24 * 365,  // days * 365
  }
  const secondsPassed = Math.abs(new Date() - date) / 1000
  const diffDays = Math.ceil(secondsPassed / durations['day'])
  if (diffDays < 1) return 'Yesterday'
  if (diffDays < 7) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`
  }
  const diffWeeks = Math.ceil(secondsPassed / durations['week'])
  if (diffWeeks < 4) {
    return `${diffWeeks} week${diffWeeks > 1 ? 's' : ''} ago`
  }
  const diffMonths = Math.ceil(secondsPassed / durations['month'])
  if (diffMonths < 12) {
    return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`
  }
  const diffYears = Math.ceil(secondsPassed / durations['year'])
  return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`
}

/**
 * @param {string} date A year-first skewer case date (YYYY-MM-DD)
 * @returns {number} Return difference in days between date and today
 */
export const getDaysDiff = (date) => !date ? 0 : Math.ceil(((new Date() - new Date(date)) / 1000) / (60 * 60 * 24))

/*******************
 STRING HELPERS
********************/

/**
 * @param {string} string A mixed case string
 * @returns {string} string converted to sentence case
 *
 * example input, output:
 * In: 'ThiS Is BaD'
 * Out: 'This is bad'
 */
export const toSentenceCase = (string) => (!string ? '' : string.slice(0,1).toUpperCase() + string.slice(1).toLowerCase())

/**
 * @param {string} string A string
 * @param {string} separator A string to split on to make a title case
 * @returns {string} string converted to Title case
 *
 * example input, output:
 * In: 'hello_world_foObAr', '_'
 * Out: 'Hello World Foobar'
 *
 *  In: 'hello.world.foObAr', '.'
 * Out: 'Hello World Foobar'
 */

const stringToTitle = (string, separator) => !string ? '' : string.split(separator).map(string => toSentenceCase(string)).join(' ')


/**
 * @param {string} snakeString A snake case string
 * @returns {string} string converted to Title case
 *
 * example input, output:
 * In: 'hello_world_foObAr'
 * Out: 'Hello World Foobar'
 */
export const snakeToTitleCase = snakeString => stringToTitle(snakeString, '_')

/**
 * @param {string} string A mixed case string
 * @returns {string} string converted to title case, split on spaces
 *
 * example input, output:
 * In: 'ThiS Is BaD'
 * Out: 'This Is Bad'
 */
export const toTitleCase = string => stringToTitle(string, ' ')

export const getOperationName = userDetails => userDetails.business_name || `${userDetails.first_name}'s Farm`

/**
 * @param {string} sourceString A source string to search in
 * @param {string} searchString A string to search for
 * @returns {boolean} boolean
 *
 * example input, output:
 * In: 'Foobar', 'Bar'
 * Out: true
 */
export const stringContainsSearchText = (string, searchText) => string.toLowerCase().includes(searchText.toLowerCase())

export const caseInsensitiveMatch = (stringA, stringB) => stringA.toLowerCase() === stringB.toLowerCase()

export const booleanToYesNo = bool => bool ? 'yes' : 'no'

export const camelize = str => (str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(''))

export const arrayToString = (arr=[], separator=', ', lastItemSeparator=arr.length > 2 ? ', and' : 'and') => `${arr.slice(0, -1).join(separator)}${arr.length > 1 ? ` ${lastItemSeparator}` : ''} ${arr[arr.length - 1]}`

export const containsAlphaNumeric = str => !!str.match(/[a-z0-9]+/i)
