import React from 'react'
import Checkmark from './Checkmark'
import Warning from './Warning'
import DownArrow from './DownArrow'
import Close from './Close'

const Icon = props => {
  switch (props.name) {
    case 'checkmark':
      return <Checkmark {...props} />
    case 'warning':
      return <Warning {...props} />
    case 'down arrow':
      return <DownArrow {...props} />
    case 'close':
      return <Close {...props} />
    default:
      return
  }
}

export default Icon
