import { ADD_FLASH_MESSAGE, REMOVE_FLASH_MESSAGE } from './messages-actions'


// Initial AuthReducer State
const initialState = {
  messages: []
}

// Auth Reducer
function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FLASH_MESSAGE:
      // only one message with the same title will be displayed at any time
      if (!state.messages.some(message => message.title === action.message.title)) {
        return Object.assign({}, state, {
          messages: [...state.messages, action.message]
        })
      } else {
        return { ...state }
      }
    case REMOVE_FLASH_MESSAGE: {
      return Object.assign({}, state, {
        messages: state.messages.filter((f) => action.value !== f[action.removeType])
      })
    }
    default: {
      return state
    }
  }
}

export default messagesReducer
