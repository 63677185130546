
import React from 'react';
import styled from 'styled-components'
import { toggleUnreleasedFeatures } from './meta-actions';
import Button from '@material-ui/core/Button'

const StyledVersionDisplay = styled.div`
  @media screen and (max-width: 980px){
    left: 10px;
  }
  position: absolute;
  top: 7px;
  right: 15px;
  z-index: 5;
  max-width: 165px;
`

const UnreleasedVisibility = ({dispatch, visibility}) => (
  <StyledVersionDisplay>
    <Button
      variant='contained'
      disableElevation
      size='small'
      onClick={()=>dispatch(toggleUnreleasedFeatures())}>
        View {
        visibility
          ? 'Production'
          : 'Unreleased'
      }
    </Button>
  </StyledVersionDisplay>
)

export default UnreleasedVisibility;
