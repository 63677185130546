import React from 'react'
import PropTypes from 'prop-types'
import { theme } from '../utils/theme'

const SVG = ({
  style = {},
  fill = theme.colors.primary,
  width = '14',
  height = '5',
  className = '',
  viewBox = '0 0 14 5'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d="M13.6673 5L7.00065 7.94992e-08L0.333984 5L13.6673 5Z" fill={fill} />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

export default SVG
