import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledBar = styled.h2`
  text-align: center;
  width: 100%
  background-color: #2ec4b287;
  margin: auto;
  position: fixed;
  font-size: 20px;
  z-index: 999;
  text-align: center;
`
const ViewOnlyBar = ({ viewedUser, viewOnly }) => (

  <StyledBar>
    {`Viewing as ${viewedUser.first_name} ${viewedUser.last_name} -- ${viewOnly ? 'No changes saved' : 'EDIT MODE'} `}
  </StyledBar>
)

ViewOnlyBar.propTypes = {
  viewedUser: PropTypes.shape({first_name: PropTypes.string, last_name: PropTypes.string}),
  viewOnly: PropTypes.bool
}

export default ViewOnlyBar
