import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { StyledUserLinks } from './styles'
import Icon from '../../common/svgs/UpArrow'

const routes = [
  {
    to: '/my-account',
    title: 'Profile'
  },
  // {
  //   to: '/samples',
  //   title: 'Samples'
  // },
  {
    to: '/field-management',
    title: 'Update Fields'
  },
  {
    to: '/my-account/permissions',
    title: 'Permissions'
  }
]

const Image = ({ currentUser }) => {
  return currentUser && currentUser.image ?
    <img src={currentUser.image} alt='' className='user' /> :
    <span className='user'>
      {currentUser && (currentUser.first_name && currentUser.last_name) ?
        `${currentUser.first_name[0]}${currentUser.last_name[0]}` : 'TU'}
    </span>
}

Image.propTypes = {
  currentUser: PropTypes.object.isRequired,
}

const Profile = ({ currentUser, isViewMode }) => {
  return (
    <div className='toggle'>
      <Image currentUser={currentUser} />
      <Icon
        style={{
          transform: 'rotate(180deg)',
          marginLeft: isViewMode ? '0' : '5px'
        }}
        width='15'
        className='down-arrow'
      />
    </div>
  )
}

Profile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isViewMode: PropTypes.bool.isRequired
}

const renderRoutes = () => {
  return routes.map((route) => {
    return (
      <div key={route.title} className='dropdown-item'>
        <NavLink to={route.to}>{route.title}</NavLink>
      </div>
    )
  })
}

const UserLinks = ({ currentUser, accountOwner = {}, logOut }) => {
  const isViewMode = currentUser.id === accountOwner.id
  return (
    <StyledUserLinks isViewMode={isViewMode}>
      <div className='user-wrapper'>
        <Profile currentUser={currentUser} isViewMode={isViewMode} />
        <div className='dropdown-menu'>
          {
            renderRoutes()
          }
          <div className='dropdown-item'>
            <NavLink onClick={logOut} to='/login'>Logout</NavLink>
          </div>
          <Image currentUser={currentUser} />
        </div>
      </div>
    </StyledUserLinks>
  )
}

export default UserLinks

UserLinks.propTypes = {
  currentUser: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  accountOwner: PropTypes.object
}
