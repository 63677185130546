import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const loader = require('../../assets/images/loader.svg')


const StyledLoadingWrapper = styled.div`
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.height && css`
    height: ${props.height};
    img {
     height: 50%;
  }`}
`

//if passed a height prop, scale appropriately
export const Loader = ({ height, overrideStyle }) => (
  <StyledLoadingWrapper height={height} style={overrideStyle}>
    <img src={loader} alt='Loading' />
  </StyledLoadingWrapper>
)
Loader.propTypes = {
  height: PropTypes.string,
  overrideStyle: PropTypes.object
}

const StyledText = styled.span`
  color: #2EC4B2;
  font-family: Lato;
  font-size: 24px;
  font-weight: 400;
  margin-left: 10px;
`

export const CircularLoader = ({ height, overrideStyle, text='Loading' }) => (
  <StyledLoadingWrapper height={height} style={overrideStyle}>
    <CircularProgress /><StyledText>{text}</StyledText>
  </StyledLoadingWrapper>
)
CircularLoader.propTypes = {
  height: PropTypes.string,
  overrideStyle: PropTypes.object,
  text: PropTypes.string
}

export default Loader
