
import { theme } from './theme'
const { colors } = theme


/**
 * Returns named risk level compared to given threshold definition
 *
 * @param {number} value value to evaluate
 * @param {object} threshold object containing high, low, and inverted keys (only high required)
 */
export const getRisk = (value, { inverted, high, low }) => {
  let risks = ['low', 'medium', 'high']
  //reverse meaning for inverted indicators
  if (!inverted) risks = risks.reverse()
  // only return risk if there is high threshold and value is defined
  if (high === null || value === null || value === undefined) return
  return (
    value > high ? risks[2]
      // use low threshold if it exists to check low risk, else automatic low risk
      : (low && value < low) ? risks[0]
        : risks[1]
  )
}

/***
 *
 * Centralizes the display name for risk for different indicator types
 * @param {string} risk one of 'low, medium, high'
 * @param {boolean} type pathogen or shi
 *
 * @returns {string} display name for risk
 **/
export const riskToName = (risk, type) => {
  const riskName = {
    pathogen: {
      'low': 'Low',
      'medium': 'Medium',
      'high': 'High'
    },
    chemistry: {
      'low': 'Low',
      'medium': 'Medium',
      'high': 'High'
    },
    shi: {
      'low': 'Favorable',
      'medium': 'Neutral',
      'high': 'Unfavorable'
    },
    soilEnv: {
      'low': 'Favorable',
      'medium': 'Neutral',
      'high': 'Unfavorable'
    }
  }
  return riskName[type]?.[risk] || 'Unbenchmarked'
}

/**
 * Returns color mapping for a given risk level
 * @param {string} risk one of 'low, medium, high'
 * @param {boolean} isText if true, will return a color variant for better contrast W/ white
 * @param {boolean} isAbsolute if true, will return a color variant for non-benchmarked thresholds
 * @returns {string} hexcode color. color if in map, gray otherwise
 */
export const getColorByRisk = (risk, isText, isAbsolute) => {
  let colorMap = {
    low: colors.map_green,
    medium: colors.map_yellow,
    high: colors.map_red,
  }
  if (isAbsolute) {
    colorMap = {
      low: colors.map_red,
      medium: colors.map_yellow,
      high: colors.map_green,
    }
  } else if (isText) {
    colorMap = {
      low: colors.map_green_dark,
      medium: colors.map_yellow_dark,
      high: colors.map_red_dark
    }
  }
  return colorMap[risk] || colors.soilGray
}


/**
 * Given a value and a threshold object, return correct color for calculated risk level
 * @param {number} value value to evaluate
 * @param {object} threshold object containing high, low, and inverted keys (only high required)
 *
 * @returns {string} hexcode color
 */
export const getColorForThreshold = (value, threshold) => {
  let risk = getRisk(value, threshold)
  return getColorByRisk(risk, false, threshold.isAbsolute)
}


export const noThresholdColorMap = [
  '#7299A0',
  '#77ABBF',
  '#69ACCB',
  '#68B7E1',
  '#3DA3E4',
  '#2F86D3',
  '#1D5EBB',
  '#0A36A3',
]
/**
 * Retrieves a 'relative' color based off the minimum and maximum values in 'allValues'
 * @param {number} value Value to generate a matching color for
 * @param {array} allValues Array of numbers to provide context for color generation
 * @returns {string} hexcode color
*/
export const getColorNoThreshold = (value, allValues = []) => {
  //do not use null and undefined to find color scale
  allValues = allValues.filter(val => val !== null && val !== undefined)
  // if no compare values, return gray
  if (!allValues.length || value == null) return colors.soilGray
  let min = Math.min(...allValues)
  let max = Math.max(...allValues)
  let ratio = (value - min) / (max - min)
  let i = 0
  while (ratio >= ((i + 1) / noThresholdColorMap.length) && i <= noThresholdColorMap.length) {
    i++
  }
  return noThresholdColorMap[i] || '#0A36A3'
}

export const pscoreThresholds = {
  'p-score-bio:trace-p-score/v1': {
    inverted: true,
    isAbsolute: true,
    low: 40,
    high: 60
  },
  'p-score-chem:trace-p-score/v1': {
    inverted: true,
    isAbsolute: true,
    low: 40,
    high: 75
  },
  'p-score-composite:trace-p-score/v1': {
    inverted: true,
    isAbsolute: true,
    low: 40,
    high: 67.5,
  }
}
