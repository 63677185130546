import { createSelector } from 'reselect'

import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAILURE
} from './products-actions'
import { groupBy, sortBy } from '../../common/utils/sortHelpers'
import { getProductDescription } from '../../components/Orders/helpers'
import { getAllByKey } from './indicator-reducer'
import { loggedInUser } from '../../components/auth/auth-reducer'

const initialState = {
  pending: false,
  serverError: null,
  byId: {},
  allIds: []
}

// constants
export const pscoreAnalysisProductId = 1

export const productsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST: return { ...state, pending: true, serverError: null }
    case PRODUCTS_FAILURE: return { ...state, pending: false, serverError: action.error }
    case PRODUCTS_SUCCESS: {
      action.products.forEach(p => p.hasBulkDensity = !!p.indicator_keys.includes('bd:s-bd-core-00'))
      return {
        ...state,
        pending: false,
        serverError: null,
        byId: { ...state.byId, ...groupBy(action.products, el => el.id, false) },
        allIds: [ ...state.allIds, action.products.map(p => p.id) ]
      }
    }
    default: return state
  }
}

const products = state => state.entities.products

// Selector for product descriptions
export const getProductsWithDescriptions = createSelector(
  [products, getAllByKey, loggedInUser],
  (products, allByKey, loggedInUser) => {
    const productsWithDescriptions = (Object.values(products.byId)).map(p => ({ ...p, description: getProductDescription(p.indicator_keys, allByKey) }))
    return {
      // Note: byDisplayName is filtered down to user products
      byDisplayName: groupBy(productsWithDescriptions.filter(p => loggedInUser.product_ids.includes(p.id)), el => el.display_name, false),
      byId: groupBy(productsWithDescriptions, el => el.id, false),
      defaultProduct: productsWithDescriptions.find(p => p.id === loggedInUser.default_product_id)
    }
  }
)

const userProductIds = state => state.auth.user?.product_ids || []
// Selector for user product options
export const getUserProductOptions = createSelector(
  [userProductIds, products, getAllByKey],
  (userProductIds, products, allByKey) => {
    let userProducts = []
    if (Object.values(products.byId).length) {
      userProducts = userProductIds.map(id => products.byId?.[id] || {})
    }
    userProductIds = sortBy(userProducts, el => el.display_name)
    const userProductsWithDescriptions = userProducts.map(p => ({ ...p, description: getProductDescription(p.indicator_keys, allByKey) }))
    return userProductsWithDescriptions
  }
)
