import {
  getBaseApiHelper,
  buildQueryString
} from '../../common/utils/apiHelpers.js'
import { appStore } from '../../index'

export const SET_REPORT_SETTINGS = 'SET_REPORT_SETTINGS'
export const CLEAR_REPORT_FILTERS = 'CLEAR_REPORT_FILTERS'

export const MODUS_XML_REQUEST = 'MODUS_XML_REQUEST'
export const MODUS_XML_SUCCESS = 'MODUS_XML_SUCCESS'
export const MODUS_XML_FAILURE = 'MODUS_XML_FAILURE'

export function setReportSettings(settings) {
  return {
    type: 'SET_REPORT_SETTINGS',
    settings
  }
}

export function clearFilters() {
  return {
    type: CLEAR_REPORT_FILTERS
  }
}

function modusXMLRequest() {
  return {
    type: MODUS_XML_REQUEST
  }
}

function modusXMLSuccess(result) {
  return {
    type: MODUS_XML_SUCCESS,
    result
  }
}

function modusXMLFailure(error) {
  return {
    type: MODUS_XML_FAILURE,
    error: error
  }
}

export const getModusXML = (growerAcctId, sampleIds, eventCode, crop) => {
  const store = appStore.getState()
  const release = store.auth.user.release
  return dispatch => {
    dispatch(modusXMLRequest())
    let params = {
      sample_ids: sampleIds,
      event_code: eventCode,
      release: release
    }
    if (crop) {
      params['crop'] = crop.toUpperCase()
    }
    const url = `/account/${growerAcctId}/results/modus_xml?${buildQueryString(params)}`
    return getBaseApiHelper(url).then(response => {
      dispatch(modusXMLSuccess(response.data))
      return response.data
    }).catch(error => {
      dispatch(modusXMLFailure(error.response))
      return Promise.reject(error.response)
    })
  }
}
