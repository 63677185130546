import { createSelector } from 'reselect'

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_LOGO_REQUEST,
  USER_LOGO_SUCCESS,
  USER_LOGO_FAILURE,
  VIEWING_USER_REQUEST,
  VIEWING_USER_SUCCESS,
  VIEWING_USER_FAILURE,
  USER_GROUPS_REQUEST,
  USER_GROUPS_SUCCESS,
  USER_GROUPS_FAILURE,
  SET_SELECTED_ACCOUNTS,
  SET_VIEW_ONLY
} from './auth-actions'
import { getAccountId } from '../../common/utils/apiHelpers'
import { permissionsByAccount } from '../../common/actions-reducers/permissionsReducer'
import { appStore } from '../../index'
import { jsonb64stringToURI } from '../../common/utils/IOHelpers'


// Initial AuthReducer State
const initialState = {
  isFetching: false,
  // if authToken has been generated, user has logged in and verified their email
  isAuthenticated: sessionStorage.getItem('authToken') ? true : false,
  user: JSON.parse(sessionStorage.getItem('user')) || {},
  errorMessage: '',
  groups: {},
  isViewOnly: !!sessionStorage.getItem('viewingAccountId'),
  viewingUser: null,
  selectedAccounts: [getAccountId()]
}
// Auth Reducer
export function authReducer(state = initialState, action) {
  if (action?.user?.logo){
    action.user.logo = jsonb64stringToURI(action.user.logo)
  }
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        errorMessage: '',
        authToken: action.authToken,
        user: action.user,
        selectedAccounts: [getAccountId()]
      })
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        errorMessage: action.message,
        authToken: undefined,
        user: {}
      })
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        authToken: undefined,
        user: {}
      })
    case REGISTER_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      })
    case REGISTER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching
      })
    case REGISTER_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        user: {}
      })
    case USER_UPDATE_REQUEST:
    case USER_LOGO_REQUEST:
    case USER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case USER_UPDATE_SUCCESS:
    case USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        user: action.user,
        isAuthenticated: action.isAuthenticated
      })
    case USER_UPDATE_FAILURE:
    case USER_LOGO_FAILURE:
    case USER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.error
      })

    case USER_LOGO_SUCCESS:
      return {...state, user: {...state.user, logo: jsonb64stringToURI(action.response)}}

    case VIEWING_USER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case VIEWING_USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        viewingUser: action.user
      })
    case VIEWING_USER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.error
      })

    case USER_GROUPS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      }
    case USER_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        groups: action.groups
      }
    case USER_GROUPS_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      }
    case SET_SELECTED_ACCOUNTS:
      localStorage.setItem(
        `selectedAccounts-${sessionStorage.getItem('userId')}`,
        JSON.stringify(action.payload)
      )
      return {
        ...state,
        selectedAccounts: action.payload
      }
    case SET_VIEW_ONLY:
      return {
        ...state,
        isViewOnly: action.payload
      }
    default:
      return state
  }
}

const selectedAccounts = state => state.auth.selectedAccounts
export const loggedInUser = state => state.auth.user

// Selector for when multiple accounts selectable at once
export const getSelectedAccounts = createSelector(
  [permissionsByAccount, selectedAccounts, loggedInUser],
  (permissions, ids, user)=>(ids[0] ? ids : []).map(id=>(
    permissions[id] ?
      {id: id, account_name: permissions[id].account_name} :
      {...user, id: user.account_id}))
)

export const getSelectedAccountNamesById = () => getSelectedAccounts(appStore.getState())
  .reduce((acc, {account_name, id}) => ({ ...acc, [id]: account_name }), {})
