import {
  getBaseApiHelper,
  postBaseApiHelper,
  patchBaseApiHelper,
  getAccountId
} from '../../common/utils/apiHelpers'



export const UPDATE_PARAMETER = 'UPDATE_PARAMETER'
export const ADD_PARAMETER = 'ADD_PARAMETER'
export const DELETE_PARAMETER = 'DELETE_PARAMETER'
export const SET_DETAIL = 'SET_DETAIL'
export const SET_RESULTS = 'SET_RESULTS'
export const SET_FILTER = 'SET_FILTER'

export const GET_ACCOUNT_RULES_REQUEST = 'GET_ACCOUNT_RULES_REQUEST'
export const GET_ACCOUNT_RULES_SUCCESS = 'GET_ACCOUNT_RULES_SUCCESS'
export const GET_ACCOUNT_RULES_FAILURE = 'GET_ACCOUNT_RULES_FAILURE'

export const SAVE_RULE_REQUEST = 'SAVE_RULE_REQUEST'
export const SAVE_RULE_SUCCESS = 'SAVE_RULE_SUCCESS'
export const SAVE_RULE_FAILURE = 'SAVE_RULE_FAILURE'

export const SELECT_RULE = 'SELECT_RULE'

export function updateParameter(idx, params) {
  return {
    type: UPDATE_PARAMETER,
    idx,
    params
  }
}

export function addParameter() {
  return {
    type: ADD_PARAMETER,
  }
}

export function deleteParameter(idx) {
  return {
    type: DELETE_PARAMETER,
    idx,
  }
}

export function setRuleDetail(details) {
  return {
    type: SET_DETAIL,
    details
  }
}

export function setResults(results) {
  return {
    type: SET_RESULTS,
    results
  }
}

export function setFilter(filters) {
  return {
    type: SET_FILTER,
    filters
  }
}

// get saved rules
export const getAccountRulesRequest = () => {
  return {
    type: GET_ACCOUNT_RULES_REQUEST
  }
}
export const getAccountRulesSuccess = (rules) => {
  return {
    type: GET_ACCOUNT_RULES_SUCCESS,
    rules
  }
}
export const getAccountRulesFailure = (error) => {
  return {
    type: GET_ACCOUNT_RULES_FAILURE,
    error
  }
}

export const getAccountRules = () => {
  const accountId = getAccountId()
  return dispatch => {
    dispatch(getAccountRulesRequest())
    return getBaseApiHelper(`/account/${accountId}/rules`)
      .then(response => {
        dispatch(getAccountRulesSuccess(response.data))
      })
      .catch(rejected => {
        dispatch(getAccountRulesFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}


// save new/existing rule
export const saveRuleRequest = () => {
  return {
    type: SAVE_RULE_REQUEST
  }
}
export const saveRuleSuccess = (rule) => {
  return {
    type: SAVE_RULE_SUCCESS,
    rule
  }
}
export const saveRuleFailure = (error) => {
  return {
    type: SAVE_RULE_FAILURE,
    error
  }
}

export const saveRule = (rule) => {
  const acctId = getAccountId()
  const endpoint  = rule.id ? `/rules/${rule.id}` : `/rules`
  const func = rule.id ? patchBaseApiHelper : postBaseApiHelper
  return dispatch => {
    dispatch(saveRuleRequest())
    return func(endpoint, {...rule, account_id: acctId})
      .then(response => {
        return dispatch(saveRuleSuccess(response.data))
      })
      .catch(rejected => {
        dispatch(saveRuleFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

export const selectRule = (ruleId) => {
  return {
    type: SELECT_RULE,
    ruleId
  }
}
