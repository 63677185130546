import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { StyledFlashMessage } from './styles'
import { removeFlashMessage } from './messages-actions'
import Icon from '../../common/svgs/Icon'


class FlashMessages extends Component {
  constructor(props) {
    super(props)

    this.renderMessage = this.renderMessage.bind(this)
  }

  renderMessage(message) {
    let iconName
    if (message.type === 'success') {
      iconName = 'checkmark'
    } else {
      iconName = 'warning'
    }

    return (
      <div
        key={message.id}
        role="alert"
        className={`flash-message alert alert-${message.type} `}
        onClick={() => this.props.removeFlashMessage(message.id)}
      >
        <button type="button" className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div>
          <Icon className="icon" name={iconName} width={'15'} />
          {message.title &&
            <strong>{message.title}</strong>
          }
          &nbsp;
          {message.text}

          {message.text2 &&
            <span>
              &nbsp;
              {message.text2}
            </span>
          }
        </div>
      </div>
    )
  }

  render() {
    const messages = this.props.messages
    if (messages.length) {
      return (
        <StyledFlashMessage>
          { messages.map((message) => this.renderMessage(message))}
        </StyledFlashMessage>
      )
    }
    return (<></>)
  }
}

FlashMessages.propTypes = {
  messages: PropTypes.array,
  removeFlashMessage: PropTypes.func
}

const mapStateToProps = ({ messagesReducer }) => {
  return {
    messages: messagesReducer.messages
  }
}

export default connect(
  mapStateToProps,
  { removeFlashMessage })(FlashMessages)
