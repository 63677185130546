import axios from 'axios'

import { appStore, history } from '../../index'
import { logoutUser } from '../../components/auth/auth-actions'
import { addFlashMessage } from '../../components/FlashMessages/messages-actions'

const BASE_AUTH_URL = `${process.env.REACT_APP_BASE_API_URL}/auth`
const BASE_USERS_URL = `${process.env.REACT_APP_USERS_SERVICE_URL}`
const BASE_ACCT_URL = `${process.env.REACT_APP_USERS_SERVICE_URL}/account/`
const BASE_ENTERPRISE_URL = `${window.location.origin}/enterprise`
//rational for functions vs constants: need to make sure urls are updated on user change
export const getAccountId = () => sessionStorage.getItem('accountId')
  ? sessionStorage.getItem('accountId').slice(1,-1)
  : ''
const getToken = () => sessionStorage.getItem('authToken') || ''
const getHeaders = () => ({
  'Content-Type': `application/json`,
  'Authorization': `Bearer ${getToken()}`
})

export const buildQueryString = data => {
  if (typeof (data) === 'string') return data
  let query = []
  for (let key in data) {
    if (key in data) {
      // Encode each key and value, concatenate them into a string, and push them to the array
      if (typeof (data[key]) === 'string' || typeof (data[key]) === 'number' || typeof (data[key]) === 'boolean') {
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      } else if (Array.isArray(data[key])) {
        // 'http://localhost/api/samples?tube_ids=TGI_123&tube_ids=TGI_456&include_values=False'
        data[key].forEach(el => {
          query.push(encodeURIComponent(key) + '=' + encodeURIComponent(el))
        })
      }
    }
  }
  return query.join('&')
}

export const API_ERROR = 'API_ERROR'

// used to intercept axios response errors
axios.interceptors.response.use(undefined, error => {
  if (error.message === 'Network Error'  && !error.response) {
    appStore.dispatch(addFlashMessage({
      type: 'danger',
      title: 'Network Error',
      text: 'Please ensure your network is connected',
    }))
  }

  const status = error?.response?.status

  if (status === 401) {
    appStore.dispatch(logoutUser())
  }

  if (status === 403) {
    appStore.dispatch({ type: API_ERROR, status })
    history.push('/unauthorized')
  }
  return Promise.reject(error)
})

// cancels all requests on logout
let cancelSource = axios.CancelToken.source()
axios.interceptors.request.use(config => ({ ...config, cancelToken: cancelSource.token }))
export const cancelAllRequests = message => { cancelSource.cancel(message) }
export const resetCancelSource = () => { cancelSource = axios.CancelToken.source() }

// function factories
const getWrapper = (url) => (
  (endpoint, headers={}) => (
    axios.get(
      `${url}${endpoint}`,
      {headers: { ...getHeaders(), ...headers }}
    )
      .catch( (error) => {
        throw error
      })
  )
)

const postWrapper = (url)=> (
  (endpoint, data, headers={}) => (
    appStore.getState().auth.isViewOnly ? Promise.reject({response : { data: 'view-only'}}) :
      axios.post(
        `${url}${endpoint}`,
        data,
        {headers: {...getHeaders(),...headers}}
      )
      //If any API request returns unauthorized, log user out (clear tokens)
        .catch( (error) => {
          throw error
        })
  )
)

const patchWrapper = (url)=> (
  (endpoint, data, headers={}) => (
    appStore.getState().auth.isViewOnly ? Promise.reject({response : { data: 'view-only'}}) :
      axios.patch(
        `${url}${endpoint}`,
        data,
        {headers: {...getHeaders(),...headers}}
      )
        .catch( (error) => {
          throw error
        })
  )
)

const deleteWrapper = (url)=> (
  (endpoint, headers={}) => (
    appStore.getState().auth.isViewOnly ? Promise.reject({response : { data: 'view-only'}}) :
      axios.delete(
        `${url}${endpoint}`,
        {headers: {...getHeaders(),...headers}}
      )
        .catch( (error) => {
          throw error
        })
  )
)

/**
 * Calls to axios with specified endpoint with headers and an variabled urls
 * default headers = {
 *  'Content-Type': 'application/json',
 *  'Authorization': `Bearer {local storage authToken}`
 * }
 *
 * @param {string} endpoint - endpoint to hit. ex. "/crops", "/samples"
 * @param {Object} data - data to send in body of post
 * @param {Object} [headers] - headers to extend or overwrite the defaults
 *
 * @returns {Promise} result of axios call
 *
 */

// AUTH
export const getBaseAuthHelper = getWrapper(BASE_AUTH_URL)
export const postBaseAuthHelper = postWrapper(BASE_AUTH_URL)
export const patchBaseAuthHelper = patchWrapper(BASE_AUTH_URL)
// API/USERS
export const getBaseApiHelper = (endpoint, headers) => getWrapper(BASE_USERS_URL)(endpoint, headers)
export const postBaseApiHelper = (endpoint, data, headers) => postWrapper(BASE_USERS_URL)(endpoint, data, headers)
export const patchBaseApiHelper = (endpoint, data, headers) => patchWrapper(BASE_USERS_URL)(endpoint, data, headers)
export const deleteBaseApiHelper = (endpoint, data, headers) => deleteWrapper(BASE_USERS_URL)(endpoint, data, headers)
// ACCOUNT
export const getAccountApiHelper = (endpoint, headers) => getWrapper(`${BASE_ACCT_URL}${getAccountId()}`)(endpoint, headers)
export const postAccountApiHelper = (endpoint, data, headers) => postWrapper(`${BASE_ACCT_URL}${getAccountId()}`)(endpoint, data, headers)
export const patchAccountApiHelper = (endpoint, data, headers) => patchWrapper(`${BASE_ACCT_URL}${getAccountId()}`)(endpoint, data, headers)
export const deleteAccountApiHelper = (endpoint, data, headers) => deleteWrapper(`${BASE_ACCT_URL}${getAccountId()}`)(endpoint, data, headers)
// Enterprise
export const getEnterpriseApiHelper = (endpoint, headers) => getWrapper(BASE_ENTERPRISE_URL)(endpoint, headers)

