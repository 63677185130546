import {
  REPORT_PREFERENCES_REQUEST,
  REPORT_PREFERENCES_SUCCESS,
  REPORT_PREFERENCES_FAILURE,
} from './reportpreferences-actions'


// Initial State
const initialState = {
  byReportType: {},
}

export const reportPreferencesReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case REPORT_PREFERENCES_REQUEST:
      return {
        ...state,
        byReportType: {
          ...state.byReportType,
          [action.reportType]: {
            ...state.byReportType[action.reportType],
            isFetching: true
          }
        }
      }
    case REPORT_PREFERENCES_SUCCESS:
      return {
        ...state,
        byReportType: {
          ...state.byReportType,
          [action.reportType]: {
            ...action.preferences,
            isFetching: false
          }
        }
      }
    case REPORT_PREFERENCES_FAILURE:
      return {
        ...state,
        byReportType: {
          ...state.byReportType,
          [action.reportType]: {
            ...state.byReportType[action.reportType],
            isFetching: false
          }
        }
      }
    default: {
      return state
    }
  }
}

