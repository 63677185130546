import { sampleUploadColumns, visibleUploadColumnsArray, getValidatorDescription } from '../../common/utils/CSVHelpers'
import { getDaysDiff, arrayToString } from '../../common/utils/stringAndDateHelpers'
import { groupBy } from '../../common/utils/sortHelpers'

/*
 * Constants
 */
// internal users only with customer edit access
const numDaysToEditOrAddSamplesAfterReceived = 60

export const updateTestPackageReminder = 'Update sample-specific test packages on the TraceView Order Details page.'

/*
 * add additional sample in sample table based on last sample
 * sample.rawData contains normalized values (ex: true instead of "yes")
 */
export const copySample = (sample,
  properties=[
    'sampling_date',
    'previous_crop',
    'current_crop',
    'next_crop',
    'sample_tags',
    'planting_stage',
    'depth',
    'min_depth',
    'max_depth',
    'depth_unit',
    'product_id',
    'client_reference'
  ]) => (
  properties.reduce((acc, property) => {
    if (sample && (sample[property] || typeof sample[property] === 'number')) {
      acc[property] = sample[property]
      acc.rawData[property] = sample.rawData[property]
    }
    return acc
  }, {rawData: {}})
)

/*
 * remove properties that aren't accepted for
 *   POST in orderSample schema
 *   PATCH in sample schemas
 * format lat/lng to 6 decimal places
 * convert inclusion of bio, chem to boolean
 */
export const cleanSample = (sample,
  userProductOptions,
  user,
  order,
  properties=[
    'sample_name',
    'latitude',
    'longitude',
    'include_bio',
    'include_chem',
    'sample_tags',
    'sampling_date',
    'min_depth',
    'max_depth',
    'depth_unit',
    'planting_stage',
    'previous_crop',
    'current_crop',
    'next_crop',
    'product_id',
    'product_name',
    'client_reference',
    'core_diameter',
    'core_diameter_unit',
    // properties that other processes are dependent on
    'id',
  ]) => {
  let userProductNameIdMap = {}
  if (userProductOptions?.length) {
    userProductNameIdMap = userProductOptions.reduce((acc, {display_name, id}) => {
      acc[display_name] = id
      return acc
    }, {})
  }
  const orderHasProcessedSample = order?.samples?.some(s => s.processed_date || s.chem_processed_date)
  return properties.reduce((cleanedSample, property) => {
    if (property in sample) {
      if (['latitude', 'longitude'].includes(property)) {
        if (sample[property]) {
          cleanedSample[property] = Number(sample[property]).toFixed(6)
        }
      } else if (sample[property] === '') {
        cleanedSample[property] = null
      } else if (!orderHasProcessedSample && ['product_name'].includes(property)) {
        cleanedSample['product_id'] = userProductNameIdMap?.[sample?.product_name] || user?.default_product_id
      } else {
        cleanedSample[property] = sample[property]
      }
    }
    return cleanedSample
  }, {})
}

export const getSampleNameAndDepthKey = sample => `${sample.sample_name}::${sample.min_depth}::${sample.max_depth}::${sample.depth_unit}`

export const getSampleNameSet = samples => samples.reduce((acc, cur) => acc.add(cur.sample_name), new Set())

export const getSampleNameAndDepthKeySet = samples => samples.reduce((acc, cur) => acc.add(getSampleNameAndDepthKey(cur)), new Set())

export const getGrowerFarmAndFieldKey = sample => `${sample.farm_name}::${sample.field_name}`
export const getSampleV2Key = sample => `${getGrowerFarmAndFieldKey(sample)}::${sample.sample_name}::${sample.min_depth}::${sample.max_depth}::${sample.depth_unit}`
export const getSampleV2KeySet = samples => samples.reduce((acc, cur) => acc.add(getSampleV2Key(cur)), new Set())

export const checkSampleForErrors = (sample={}, sampleData=[], hasBulkDensity) => {
  const errors = {}
  void ['sample_name', 'depth_unit'].forEach(property => {
    if (!sampleUploadColumns[property].validators.notFalsy(sample[property])) {
      errors[property] = getValidatorDescription('notFalsy')
    }
  })

  void ['min_depth', 'max_depth'].forEach(property => {
    const depthUnit = {depthUnit: sample.depth_unit}
    const { typeNumber, validDepth } = sampleUploadColumns[property].validators
    if (!typeNumber(sample[property])) {
      errors[property] = getValidatorDescription('typeNumber')
    } else if (!validDepth(sample[property], depthUnit)) {
      errors[property] = getValidatorDescription('validDepth', depthUnit)
    }
  })
  if (sampleData.some(existingSample => getSampleNameAndDepthKey(existingSample) === getSampleNameAndDepthKey(sample))) {
    ['sample_name', 'min_depth', 'max_depth', 'depth_unit'].forEach(property => errors[property] = getValidatorDescription('sampleIsUnique'))
  }
  if (sample.latitude && !sampleUploadColumns.latitude.validators.validLat(sample.latitude)) {
    errors.latitude = getValidatorDescription('validLat')
  }
  if (sample.longitude && !sampleUploadColumns.longitude.validators.validLong(sample.longitude)) {
    errors.longitude = getValidatorDescription('validLong')
  }

  if (typeof sample.min_depth === 'number') {
    if (!sampleUploadColumns.min_depth.validators.lessThanMaxDepth(sample.min_depth, {maxDepth: sample.max_depth})) {
      errors.min_depth = getValidatorDescription('lessThanMaxDepth')
    }
  }

  if (typeof sample.core_diameter === 'number' || hasBulkDensity) {
    const coreDiameterUnit = {coreDiameterUnit: sample.core_diameter_unit}
    const { validCoreDiameter } = sampleUploadColumns.core_diameter.validators
    if (!validCoreDiameter(sample.core_diameter, coreDiameterUnit)) {
      errors.core_diameter = getValidatorDescription('validCoreDiameter', coreDiameterUnit)
    }
  }
  return errors
}

export const sampleIsMissingDataCol = (sample, colName) => {
  if (!sample[colName]) {
    // optional (TODO: this needs a design that isn't hard coding duplicate information from backend)
    if (['sample_tags', 'next_crop', 'planting_stage', 'client_reference', 'core_diameter', 'core_diameter_unit'].includes(colName)) return false

    // either previous or current crop are required
    if (colName === 'previous_crop' && sample['current_crop']) return false
    if (colName === 'current_crop' && sample['previous_crop']) return false

    // zeros are valid values
    if (typeof sample[colName] === 'number' && sample[colName] === 0) return false

    return true
  }
}

// zeros are valid numeric values
export const validateValue = val => typeof val === 'number' ? val : val || ''

/**
 * @param {object} viewingUser
 * @param {object} sample
 * @param {string} sampleProperty
 * @returns {boolean} boolean
 */
export const userCanEditSampleProperty = (viewingUser, sample, sampleProperty) => {
  // any non-product property can be edited
  if (!['product_id'].includes(sampleProperty)) return true
  // user without customer edit access can NOT edit product properties after sample is received
  // user with customer edit access can NOT edit product properties after n days from sample receipt
  if ((!viewingUser?.flags?.['allow-edit'] && sample.received_date) ||
    getDaysDiff(sample.received_date) > numDaysToEditOrAddSamplesAfterReceived) return false
  // product can NOT be edited by user without customer edit access after bio OR chem results return
  if (sampleProperty === 'product_id') return (!sample.processed_date && !sample.chem_processed_date) || viewingUser?.flags?.['allow-edit']
}

export const userCanAddSamples = (viewingUser, samples) => {
  // user without customer edit access can NOT add samples after EVERY sample is received
  // user with customer edit access can NOT add samples after n days from EARLIEST sample receipt date
  if ((!viewingUser?.flags?.['allow-edit'] && !!samples.length && samples.every(s => s.received_date)) ||
    samples.some(sample => getDaysDiff(sample.received_date) > numDaysToEditOrAddSamplesAfterReceived)) return false
  return true
}

export const getProductDescription = (indicatorKeys, allByKey) => {
  const indicators = indicatorKeys.filter(k => allByKey[k]).map(k => allByKey[k])
  const indicatorsByType = groupBy(indicators, el => el.indicator_type)
  const description = {}
  for (const type in indicatorsByType) {
    if (type === 'chemistry') {
      description[type] = arrayToString(indicatorsByType[type].map(i => i.name))
    } else if (type === 'pathogen') {
      description.biology = description.biology || []
      description.biology.push('Whole Genome Sequencing - Pathogens')
    } else if (type === 'shi') {
      description.biology = description.biology || []
      description.biology.push('Nutrient Cycling Indicators')
    }
  }
  if (description.biology) {
    description.biology = arrayToString(description.biology)
  }
  return description
}

export const orderDetailColumns = visibleUploadColumnsArray.reduce((acc, col) => {
  if (col.name !== 'product_name') {
    acc[col.name] = col
  }
  if (col.name === 'longitude') {
    acc['product_id'] = {
      name: 'product_id',
      display: 'Test Package',
      validators: {},
      normalizers: [],
      warnings: [],
    }
  }
  return acc
}, {})

export const orderHasBulkDensity = (order, productDescriptions) => order?.samples.some(s => productDescriptions.byId[s.product_id]?.hasBulkDensity)

export const isEditMode = (sample, selectedSample, modal, isCopyingToAll) => sample.id === selectedSample?.id
  && !['deleteSample'].includes(modal?.modalType)
  && !isCopyingToAll

export const samplePropertiesForCopyToAll = () => (
  {
    sample_tags: true,
    product_id: true,
    sampling_date: true,
    min_depth: true,
    max_depth: true,
    depth_unit: true,
    planting_stage: true,
    previous_crop: true,
    current_crop: true,
    next_crop: true,
    client_reference: true,
    core_diameter: true,
    core_diameter_unit: true
  }
)
