import {getBaseApiHelper} from '../utils/apiHelpers'

export const REPORT_REQUEST = 'REPORT_REQUEST'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAILURE = 'REPORT_FAILURE'

/**
Download report by order ID
**/
function reportRequest(reportType, orderId) {
  return {
    type: REPORT_REQUEST,
    reportType,
    orderId
  }
}

function reportSuccess(reportType, orderId, report) {
  return {
    type: REPORT_SUCCESS,
    report,
    reportType,
    orderId
  }
}

function reportFailure(reportType, orderId, error) {
  return {
    type: REPORT_FAILURE,
    reportType,
    orderId,
    error,
  }
}

export function getReport(reportType, orderId) {
  return dispatch => {
    dispatch(reportRequest(reportType, orderId))
    return getBaseApiHelper(`/reports/orders/${orderId}/${reportType}`)
      .then(response => dispatch(reportSuccess(reportType, orderId, response.data.report)))
      .catch(rejected => {
        dispatch(reportFailure(reportType, orderId, rejected))
        return Promise.reject(rejected)
      })
  }
}
