import styled from 'styled-components'

export const StyledNotFoundPage = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	font-family: inherit;

	img{
		width: 200px;
		height: 200px;
	}

	h1{
		font-size: 100px;
		color: ${props => props.theme.colors.primary}
		font-weight: 900;
		line-height: 100px;
		margin-top: 40px;
		margin-bottom: 0;
		height: 100px;
	}

	h3{
		color: #c4c4c4;
		font-weight: 600;
	}

	p{
		margin-top: 20px;
		color: #6d6e71;
	}

	button{
		margin-top: 40px;
		padding: 12px 0;
		font-size: 14px;
		line-height: 14px;
		color: #ffffff;
		border: 0px;
		border-radius: 4px;
		transition: background-color 0.3s ease-in-out;
		cursor: pointer;
		background-color: #04afaa;
		width: 200px;
		margin-bottom: 25px;
		font-family: inherit;
		font-weight: 700;
		:hover {
		    background-color: #029c98;
		}

		:focus{
			outline: none;
		}
	}
`
