import { appStore } from '../../index'

import {
  CONSTANTS_REQUEST,
  CONSTANTS_SUCCESS,
  CONSTANTS_FAILURE,
} from '../../common/actions-reducers/constants-actions'


// Initial State
const initialState = {
  isFetching: false,
  crops: [],
}

export function cropsReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case CONSTANTS_REQUEST:
      return { ...state, isFetching: true }
    case CONSTANTS_SUCCESS:
      var crops = Object.keys(action.constants.crop_pathogens).sort()
      return {
        ...state,
        isFetching: false,
        crops,
      }
    case CONSTANTS_FAILURE:
      return { ...state, isFetching: false, errorMessage: action.error }
    default: {
      return state
    }
  }
}

export const cropsFromState = () => appStore.getState().entities.crops.crops
