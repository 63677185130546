import {
  TAGS_REQUEST,
  TAGS_SUCCESS,
  TAGS_FAILURE
} from './tags-actions'

const initialState = {
  pending: false,
  serverError: null,
  byAccount: {}
}

export const tagsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case TAGS_REQUEST: {
      return { ...state, pending: true, serverError: null }
    }

    case TAGS_SUCCESS: {
      return {
        ...state,
        byAccount: {
          ...state.byAccount,
          [action.account_id]: action.tags.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)
        },
        pending: false
      }
    }

    case TAGS_FAILURE: {
      return { ...state, pending: false, serverError: action.error }
    }

    default: {
      return state
    }
  }
}
