import React from 'react';
import { connect } from 'react-redux';
import { Route,  Redirect } from 'react-router-dom';
import NotFound from '../components/NotFound/NotFound';


let PrivateRoute = (route_props) =>{
  return (
    <Route {...route_props} render={(render_props) => (
      route_props.isAuthenticated
        ? route_props.render({route_props, ...render_props})
        : <Redirect to={{
            pathname: '/login',
            state: { from: route_props.location }
          }} />
    )} />
  )
}
PrivateRoute = connect(
  store=> ({ isAuthenticated: store.auth.isAuthenticated})
)(PrivateRoute)

let UnreleasedRoute = (props) =>{
  if (!props.unreleasedVisible){
    return (
      <Route {...props} render={() => <NotFound/>}/>
    )
  }
  return <PrivateRoute {...props}/>
}

UnreleasedRoute = connect(
  store=> ({ unreleasedVisible: store.meta.unreleasedVisible})
)(UnreleasedRoute)

let BetaRoute = (props) =>{
  if (!props.betaVisible){
    return (
      <Route {...props} render={() => <NotFound/>}/>
    )
  }
  return <PrivateRoute {...props}/>
}

BetaRoute = connect(
  store=> ({ betaVisible: store.meta.betaVisible})
)(BetaRoute)

export { PrivateRoute, UnreleasedRoute, BetaRoute}



