import {
  UPDATE_PARAMETER,
  ADD_PARAMETER,
  DELETE_PARAMETER,
  SET_DETAIL,
  SET_RESULTS,
  SET_FILTER,

  GET_ACCOUNT_RULES_REQUEST,
  GET_ACCOUNT_RULES_SUCCESS,
  GET_ACCOUNT_RULES_FAILURE,

  SAVE_RULE_REQUEST,
  SAVE_RULE_SUCCESS,
  SAVE_RULE_FAILURE,

  SELECT_RULE
} from './rule-actions'
import { appStore } from '../../index'
import {
  SET_SELECTED_ACCOUNTS
} from '../auth/auth-actions'

const initialState = {
  parameters: [],
  name: '',
  crop: '',
  selectedRuleId: '',
  results: null,
  filters: {farms:[], startDate: '', endDate: ''},
  calculatedParameters: [],
  savedRules: null,
  pending: false,
  error: undefined
}

const defaultParameterValues = {
  indicator_key: '',
  operator: '',
  value: null,
  unit_type: '',
  value_type: 'average',
  indicatorDefinition: {}
}

function ruleReducer(state = initialState, action) {

  switch (action.type) {
    case UPDATE_PARAMETER: {
      let newParameter = { ...state.parameters[action.idx], ...action.params }
      let newParameters = [...state.parameters]
      newParameters[action.idx] = newParameter
      return {
        ...state,
        parameters: newParameters
      }
    }
    case ADD_PARAMETER: {
      return {
        ...state,
        parameters: [...state.parameters, {
          ...defaultParameterValues
        }]
      }
    }
    case DELETE_PARAMETER: {
      let newParameters = [...state.parameters]
      newParameters.splice(action.idx, 1)
      return {
        ...state,
        parameters: newParameters
      }
    }
    case SET_DETAIL: {
      return {
        ...state,
        ...action.details
      }
    }
    case SET_RESULTS: {
      return {
        ...state,
        results: action.results,
        calculatedParameters: state.parameters.map(parameter => ({ ...parameter }))
      }
    }
    case SET_FILTER: {
      return {
        ...state,
        filters: {...state.filters, ...action.filters}
      }
    }
    // GET saved rules
    case GET_ACCOUNT_RULES_REQUEST: {
      return {
        ...state,
        pending: true,
        error: undefined
      }
    }

    case GET_ACCOUNT_RULES_SUCCESS: {
      let rulesById = action.rules.reduce((memo, rule) => {
        memo[rule.id] = rule
        return memo
      }, {})
      return {
        ...state,
        pending: false,
        error: undefined,
        rules: rulesById
      }
    }

    case GET_ACCOUNT_RULES_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.error,
      }
    }

    // Save changes to a rule
    case SAVE_RULE_REQUEST: {
      return {
        ...state,
        pending: true,
        error: undefined
      }
    }

    case SAVE_RULE_SUCCESS: {
      const saveIndicators = appStore.getState().entities.indicators.indicators
      const rule = action.rule
      return {
        ...state,
        pending: false,
        error: undefined,
        selectedRuleId: action.rule.id,
        parameters: action.rule.parameter_list.map(param=>{
          let indicator = saveIndicators.crop_pathogens?.[rule.crop]?.[param.indicator_key] ||
                          saveIndicators.allByKey?.[param.indicator_key] ||
                          {}
          return {
            ...param,
            indicatorDefinition: indicator,
            display_unit: param.unit_type === 'percent' ? '%' : indicator?.display_unit
          }
        }),
        rules: {
          ...state.rules,
          [action.rule.id]: action.rule,


        }
      }
    }

    case SAVE_RULE_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.error,
      }
    }

    case SELECT_RULE: {
      let selectRule = state.rules[action.ruleId]
      if (!selectRule) {
        return {
          ...state,
          crop: '',
          name: '',
          selectedRuleId: '',
          results: null,
          calculatedParameters: [],
          parameters: []
        }
      }
      let indicators = appStore.getState().entities.indicators.indicators
      return {
        ...state,
        crop: selectRule.crop,
        name: selectRule.name,
        selectedRuleId: action.ruleId,
        results: null,
        calculatedParameters: [],
        parameters: selectRule.parameter_list.map(param=>{
          let indicator = indicators.crop_pathogens?.[selectRule.crop]?.pathogens?.[param.indicator_key] ||
                          indicators.allByKey?.[param.indicator_key] ||
                          {}
          return {
            ...param,
            indicatorDefinition: indicator,
            display_unit: param.unit_type === 'percent' ? '%' : indicator?.display_unit
          }
        })
      }
    }

    // Clear out everything except parameters and crop on user change
    case SET_SELECTED_ACCOUNTS: {
      return {
        ...initialState,
        parameters: state.parameters,
        crop: state.crop,
        name: state.name
      }
    }

    default: return state
  }
}

export default ruleReducer
