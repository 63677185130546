import { createSelector } from 'reselect'

import {
  SET_REPORT_SETTINGS,
  CLEAR_REPORT_FILTERS,
  MODUS_XML_SUCCESS,
  MODUS_XML_FAILURE,
  MODUS_XML_REQUEST
} from './analytics-actions'
import { getSelectedAccountSamplesById } from '../../common/actions-reducers/samples-reducer'
import { appStore } from '../../index'
import { getAccountId } from '../../common/utils/apiHelpers'
import { samplesFilter } from '../../common/utils/sampleHelpers'


// Initial State
const initialEmptyState = {
  selectedIndicatorType: '',
  selectedCrop: '',
  selectedIndicatorKey: '',
  selectedIndicator: null,
  grouping: 'id',
  sort: 'defaultAlpha'
}

export const emptyFilters = {
  sampleTags: [],
  field: [],
  farm: [],
  samplingDateStart: '',
  samplingDateEnd: '',
  id: [],
  includeNext: true,
  plantedCrop: [],
  isClear: true,
}

const serverResults = {
  'modusXml': '',
  'isFetching': false,
  'errors': null
}

const initialState = {
  ...initialEmptyState,
  sampleFilters: emptyFilters,
  // if setInitial == true, show the initial filter modal b/c there were
  // no filters in local storage.
  setInitial: false,
  serverResults
}

const updateLocalStorageSettings = newFilters => {
  localStorage.setItem(
    `reportSettings-${sessionStorage.getItem('userId')}`,
    JSON.stringify(newFilters)
  )
}

// Analytics Reducer
function analyticsReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_REPORT_SETTINGS: {
      // only create new sample filter object if part of payload.
      // this improve performance as samples will not be refiltered on every change
      let newFilters = state.sampleFilters
      if (action.settings['sampleFilters']){
        newFilters = {
          ...state.sampleFilters,
          ...action.settings.sampleFilters,
          isClear: false
        }
      }
      let selectedAccount = appStore.getState().auth.selectedAccounts?.[0]
      if (getAccountId() === selectedAccount || !selectedAccount) {
        updateLocalStorageSettings(newFilters)
      }
      return {
        ...state,
        ...action.settings,
        sampleFilters: newFilters
      }
    }

    case CLEAR_REPORT_FILTERS: {
      const clearFilters = { ...emptyFilters }
      let selectedAccount = appStore.getState().auth.selectedAccounts?.[0]
      if (getAccountId() === selectedAccount || !selectedAccount) {
        updateLocalStorageSettings(clearFilters)
      }
      return { ...state, sampleFilters: clearFilters }
    }

    // Server Results
    case MODUS_XML_REQUEST:
      return {
        ...state, serverResults: {
          isFetching: true,
          errors: null
        }
      }

    case MODUS_XML_SUCCESS: {
      return {
        ...state, serverResults: {
          modusXml: action.result,
          isFetching: false
        }
      }
    }

    case MODUS_XML_FAILURE:
      return {
        ...state, serverResults: {
          isFetching: false,
          errors: action.error
        }
      }

    default: {
      return state
    }
  }
}


const getSampleFilters = state => state.reportSettings.sampleFilters

const getIndicatorType = state => state.reportSettings.selectedIndicatorType

const getFilteredSamples = (filters, indicatorType, samplesById) => {
  let parsedSampleFilters = {...filters, processed: true}
  // if indicator type is chem, filter by metadata status
  if (indicatorType !== 'chemistry'){
    parsedSampleFilters['hasMetadata'] = true
  }
  return samplesFilter(Object.values(samplesById), parsedSampleFilters, undefined, true)
}
export const getFilteredSampleArray = createSelector(
  getSampleFilters,
  getIndicatorType,
  getSelectedAccountSamplesById,
  getFilteredSamples
)

export default analyticsReducer
