import { SET_MAP_SETTINGS, CLEAR_MAP_SETTINGS } from './maps-actions'


// Initial State
const initialState = {
  indicatorType: 'fields',
  selectedField: '',
  selectedCrop: '',
  selectedIndicator: '',
  samplingDate: '',
  nameFilter: '',
  farm: '',
  samplingDateStart: '',
  samplingDateEnd: '',
  selectedAccount: ''
}

// Maps Reducer
function mapsReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_MAP_SETTINGS: {
      return {
        ...state,
        ...action.settings,
      }
    }
    case CLEAR_MAP_SETTINGS: {
      return { ...initialState }
    }
    default: return state
  }
}

export default mapsReducer
