import { appStore } from '../index.js'

//track old value in closure
let previousAuth = {}
export default () => {
  return appStore.subscribe(() => {
    let { auth } = appStore.getState()
    if (auth === previousAuth) {
      return
    }
    previousAuth = auth
    let user = auth.user
    let actingUser = auth.viewingUser || auth.user
    let isInternalUserProxy = auth.viewingUser && user.account_id !== auth.viewingUser.account_id
    if (window.heap) {
      window.heap.identify(actingUser.email)
      window.heap.addUserProperties({
        'Account Email': actingUser.email,
        'Account Name': `${actingUser.first_name} ${actingUser.last_name}`,
        'Account Id': actingUser.account_id,
      })
      window.heap.addEventProperties({
        'Active Account Id': user.account_id,
        'Active Account Name': `${user.first_name} ${user.last_name}`,
        'Active Account Email': user.email,
        'Proxy Email': auth.viewingUser?.email,
        'Proxy Account': auth.viewingUser?.account_id,
        'Is User Proxy': isInternalUserProxy,
        'Account For Event': auth.selectedAccounts || user.account_id,
        'Event On Own Account': !auth.selectedAccounts.length || auth.selectedAccounts.includes(user.account_id)
      })
    }
  })
}
