import { addFlashMessage } from '../FlashMessages/messages-actions'
import { patchBaseApiHelper, postBaseApiHelper, postBaseAuthHelper, getBaseApiHelper, getAccountId, cancelAllRequests, resetCancelSource, getEnterpriseApiHelper } from '../../common/utils/apiHelpers'
import { push } from 'react-router-redux'
import { appStore } from '../../index'
import { toggleUnreleasedFeatures, setBetaAccess } from '../../App/meta-actions'
import { clearFilters } from '../Analytics/analytics-actions'

// Action Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

export const VIEWING_USER_REQUEST = 'VIEWING_USER_REQUEST'
export const VIEWING_USER_SUCCESS = 'VIEWING_USER_SUCCESS'
export const VIEWING_USER_FAILURE = 'VIEWING_USER_FAILURE'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export const USER_LOGO_REQUEST = 'USER_LOGO_REQUEST'
export const USER_LOGO_SUCCESS = 'USER_LOGO_SUCCESS'
export const USER_LOGO_FAILURE = 'USER_LOGO_FAILURE'

export const USER_GROUPS_REQUEST = 'USER_GROUPS_REQUEST'
export const USER_GROUPS_SUCCESS = 'USER_GROUPS_SUCCESS'
export const USER_GROUPS_FAILURE = 'USER_GROUPS_FAILURE'

export const SET_SELECTED_ACCOUNTS = 'SET_SELECTED_ACCOUNTS'
export const SET_VIEW_ONLY = 'SET_VIEW_ONLY'

// Action Creators
function loginRequest(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false
  }
}

function loginSuccess(token, user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    authToken: token,
    user
  }
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

function logoutRequest() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true
  }
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
}

function registerRequest() {
  return {
    type: REGISTER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  }
}
function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
    isFetching: false,
    isAuthenticated: true
  }
}

function registerError(message) {
  return {
    type: REGISTER_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST,
    isFetching: true
  }
}

function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
    isFetching: false
  }
}

function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_FAILURE,
    isFetching: false,
    error
  }
}

function updatePasswordRequest() {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    isFetching: true
  }
}

function updatePasswordSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    isFetching: false
  }
}

function updatePasswordError(error) {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    isFetching: false,
    error
  }
}

function userRequest() {
  return {
    type: USER_REQUEST,
    isFetching: true,
  }
}

function userSuccess(user) {
  return {
    type: USER_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user
  }
}

function userError(error) {
  return {
    type: USER_FAILURE,
    isFetching: false,
    error,
  }
}

function viewingUserRequest() {
  return {
    type: VIEWING_USER_REQUEST,
    isFetching: true,
  }
}

function viewingUserSuccess(user) {
  return {
    type: VIEWING_USER_SUCCESS,
    isFetching: false,
    user
  }
}

function viewingUserError(error) {
  return {
    type: VIEWING_USER_FAILURE,
    isFetching: false,
    error,
  }
}
function userUpdateRequest() {
  return {
    type: USER_UPDATE_REQUEST,
    isFetching: true
  }
}

function userUpdateSuccess(user) {
  return {
    type: USER_UPDATE_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: user
  }
}

function userUpdateError(error) {
  return {
    type: USER_UPDATE_FAILURE,
    isFetching: false,
    error
  }
}

function userLogoRequest() {
  return {
    type: USER_LOGO_REQUEST,
    isFetching: true
  }
}

function userLogoSuccess(response) {
  return {
    type: USER_LOGO_SUCCESS,
    isFetching: false,
    response
  }
}

function userLogoError(error) {
  return {
    type: USER_LOGO_FAILURE,
    isFetching: false,
    error
  }
}

// Thunks/Async Action Creators
export function getUser() {
  const userId = JSON.parse(sessionStorage.getItem('userId'))
  const authToken = sessionStorage.getItem('authToken')
  return dispatch => {
    dispatch(userRequest())
    getBaseApiHelper(`/users/${userId}`)
      .then(response => {
        getEnterpriseApiHelper(`/check?authToken=${authToken}&userId=${userId}`)
          .then(enterpriseResponse => {
            const hasEnterpriseSetup = !!enterpriseResponse.data.enterprise
            response.data['hasEnterpriseSetup'] = hasEnterpriseSetup
          })
          .catch(error => {
            console.error('Error performing enterprise check', error)
            response.data['hasEnterpriseSetup'] = false
          })
          .finally(() => {
            dispatch(userSuccess(response.data))
            if (response.data.flags['allow-beta-external']) {
              dispatch(setBetaAccess(true))
            }
          })
      }).catch(error => {
        dispatch(userError(error.response.data))
        return error.response.data
      })
  }
}

export function getViewingUser() {
  const userId = JSON.parse(sessionStorage.getItem('viewingUserId'))
  return dispatch => {
    dispatch(viewingUserRequest())
    getBaseApiHelper(`/users/${userId}`)
      .then(response => {
        dispatch(viewingUserSuccess(response.data))
      }).catch(error => {
        dispatch(viewingUserError(error.response.data))
        return error.response.data
      })
  }
}

export function loginUser(creds) {
  return dispatch => {
    resetCancelSource()
    dispatch(loginRequest(creds))
    return postBaseAuthHelper('/login', creds)
      .then(response => {
        window.sessionStorage.setItem('authToken', response.data.auth_token)
        window.sessionStorage.setItem('accountId', JSON.stringify(response.data.user.account_id))
        window.sessionStorage.setItem('userId', JSON.stringify(response.data.user.id))

        dispatch(loginSuccess(response.data.auth_token, response.data.user))
        // update auth reducer with current user and associated flags
        dispatch(getUser())
        return response
      }).catch(error => {
        dispatch(loginError(error.response.data.message))
        return error.response
      })
  }
}

export function logoutUser() {
  return dispatch => {
    if (appStore.getState().meta.unreleasedVisible) {
      dispatch(toggleUnreleasedFeatures())
    }
    dispatch(logoutRequest())
    cancelAllRequests(LOGOUT_REQUEST)
    sessionStorage.removeItem('authToken')
    sessionStorage.removeItem('accountId')
    sessionStorage.removeItem('userId')
    sessionStorage.removeItem('viewingAccountId')
    sessionStorage.removeItem('viewingUserId')
    sessionStorage.removeItem('unreleased')
    sessionStorage.removeItem('edit')
    window.heap && window.heap.resetIdentity()
    dispatch(push('/login'))
    dispatch(logoutSuccess())
    resetCancelSource()
  }
}

export function registerUser(data) {
  return dispatch => {
    dispatch(registerRequest())
    return postBaseAuthHelper('/register', data)
      .then(response => {
        dispatch(registerSuccess())
      }).catch(error => {
        dispatch(registerError(error.response.data.message))

        return error.response.data
      })
  }
}

export function resetPassword(creds) {
  /* type(creds) = Object */
  return dispatch => {
    dispatch(resetPasswordRequest())
    return postBaseAuthHelper('/reset_password', { email: creds.email })
      .then(response => {
        dispatch(resetPasswordSuccess())
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Password Reset ',
          text: 'Your password has been reset.',
          text2: 'Check your email for your temporary password. '
        }))
      }).catch(error => {
        dispatch(resetPasswordError(error?.response?.data?.message))
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Password Failed to Reset! ',
          text: 'Something went wrong.'
        }))
        return Promise.reject(error.response?.data.message)
      })
  }
}

export function updatePassword(creds) {
  return dispatch => {
    dispatch(updatePasswordRequest())
    return postBaseAuthHelper('/update_password', creds)
      .then(response => {
        dispatch(updatePasswordSuccess())
        // refetch the user after temp_password=False
        dispatch(getUser())
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Password Updated! ',
          text: 'Your password has successfully been updated.'
        }))
      }).catch(error => {
        dispatch(updatePasswordError(error.response.data.message))
        if (error.response.status === 403) {
          dispatch(addFlashMessage({
            type: 'danger',
            title: 'Account Password Failed to Update! ',
            text: 'Make sure your old password was entered correctly.'
          }))
        } else {
          dispatch(addFlashMessage({
            type: 'danger',
            title: 'Account Password Failed to Update! ',
            text: 'Something went wrong.'
          }))
        }
        return error.response.data
      })
  }
}

// Thunks/Async Action Creators
export function updateUser(user) {
  const userId = JSON.parse(sessionStorage.getItem('userId'))
  const url = `/users/${userId}`
  return dispatch => {
    dispatch(userUpdateRequest())
    return patchBaseApiHelper(url, user)
      .then(response => {
        dispatch(userUpdateSuccess(response.data))
        // refetch the user after temp_password=False
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Account Details Updated! ',
          text: ' Your information has successfully been updated.'
        }))
      }).catch(error => {
        dispatch(userUpdateError(error.response.data))
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Account Details Failed to Update! ',
          text: 'Something went wrong.'
        }))
        return error.response.data
      })
  }
}

export function sendLogo(formData) {
  const userId = JSON.parse(sessionStorage.getItem('userId'))
  const url = `/users/${userId}/logo`
  return dispatch => {
    dispatch(userLogoRequest())
    return postBaseApiHelper(url, formData)
      .then(response => {
        dispatch(userLogoSuccess(response.data))
        return response
      }).catch(error => {
        dispatch(userLogoError(error.response.data))
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Logo could not be updated',
          text: 'Something went wrong.'
        }))
        return error.response.data
      })
  }
}

export function userGroupsRequest() {
  return {
    type: USER_GROUPS_REQUEST,
    isFetching: true,

  }
}

export function userGroupsSuccess(groups) {
  return {
    type: USER_GROUPS_SUCCESS,
    isFetching: false,
    groups: groups
  }
}

export function userGroupsFailure() {
  return {
    type: USER_GROUPS_FAILURE,
    isFetching: false
  }
}

export function fetchUserGroups() {
  return dispatch => {
    dispatch(userGroupsRequest())
    getBaseApiHelper('/users/groups')
      .then(response => {
        dispatch(userGroupsSuccess(response.data.groups))
      }).catch(error => {
        dispatch(userGroupsFailure(error.response.data))
        return error.response.data
      })
  }
}

const setAccounts = (accountIds) => {
  return {
    type: SET_SELECTED_ACCOUNTS,
    payload: accountIds
  }
}

export function setSelectedAccount(accountId) {
  return dispatch => {
    dispatch(setAccounts(accountId ? [accountId] : [getAccountId()]))
    dispatch(clearFilters())
  }
}

export function setSelectedAccounts(accountIds) {
  return dispatch => {
    dispatch(setAccounts(accountIds))
    dispatch(clearFilters())
  }
}

export function setViewOnly(bool) {
  return {
    type: SET_VIEW_ONLY,
    payload: bool
  }
}
