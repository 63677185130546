import {
  Add,
  AddPhotoAlternateOutlined,
  Apps,
  ArrowDropDown,
  ArrowForward,
  AssessmentOutlined,
  BlurLinear,
  Cancel,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutlineOutlined,
  CloudUpload,
  DashboardOutlined,
  DateRange,
  DeleteForeverOutlined,
  EditOutlined,
  FileCopyOutlined,
  FileCopy,
  InfoOutlined,
  LayersOutlined,
  RoomOutlined,
  Save,
  SpaOutlined,
  SubdirectoryArrowLeft,
  TextureOutlined,
  VisibilityOutlined,
  EditLocationSharp,
  ExpandLess,
  ExpandMore,
  RadioButtonUnchecked
} from '@material-ui/icons'
import  React from 'react'

import { ReactComponent as FieldDiscovery } from '../../common/svgs/Funnel.svg'
import { ReactComponent as PdfReport } from '../../common/svgs/PDFReport.svg'
import { ReactComponent as PdfReportDisabled } from '../../common/svgs/PDFReportDisabled.svg'
import { ReactComponent as Pencil } from '../../assets/images/pencil.svg'
import { ReactComponent as Upload } from '../../assets/images/upload.svg'
import { ReactComponent as UploadSmall } from '../../assets/images/upload_small.svg'
import { ReactComponent as FileSmall } from '../../assets/images/file_small.svg'
import { ReactComponent as Hand } from '../../assets/images/hand.svg'

import { theme } from '../../common/utils/theme'

// FMIS
export const FarmIcon = TextureOutlined
export const FieldIcon = Apps
export const GrowerIcon = SpaOutlined
export const SamplesIcon = BlurLinear
// nav
export const AnalyticsIcon = AssessmentOutlined
export const DashboardIcon = DashboardOutlined
export const FieldDiscoveryIcon = FieldDiscovery
export const MapsIcon = RoomOutlined
export const OrdersIcon = LayersOutlined
export const PdfReportIcon = PdfReport
export const PdfReportDisabledIcon = PdfReportDisabled
// tools
export const AddIcon = Add
export const AddImage = AddPhotoAlternateOutlined
export const ArrowForwardIcon = ArrowForward
export const DropDownIcon = ArrowDropDown
export const CalendarIcon = DateRange
export const CancelIcon = Cancel
export const CheckBoxIcon = CheckBox
export const CheckBoxOutlineBlankIcon = CheckBoxOutlineBlank
export const CheckCircleIcon = CheckCircle
export const CheckCircleOutlinedIcon = CheckCircleOutlineOutlined
export const CopyIcon = FileCopyOutlined
export const CopyToAllIcon = FileCopy
export const CSVUploadIcon = Upload
export const CSVUploadSmallIcon = UploadSmall
export const DeleteIcon = DeleteForeverOutlined
export const EditIcon = EditOutlined
export const EditLocation = EditLocationSharp
export const EnterKeyIcon = SubdirectoryArrowLeft
export const ExpandMoreIcon = ExpandMore
export const ExpandLessIcon = ExpandLess
export const FileSmallIcon = FileSmall
export const HandIcon = Hand
export const InfoIcon = InfoOutlined
export const PencilIcon = Pencil
export const RadioButtonIcon = RadioButtonUnchecked
export const SaveIcon = Save
export const UploadIcon = CloudUpload
export const ViewIcon = VisibilityOutlined

export const ExpandLessFilled = props => (
  <div style={{ width: 25, height: 25, borderRadius: '50%', backgroundColor: `${theme.colors.janeGray}` }}>
    <ExpandLess style={{fill: `${theme.colors.white}`}} { ...props } />
  </div>
)

export const ExpandMoreFilled = props => (
  <div style={{ width: 25, height: 25, borderRadius: '50%', backgroundColor: `${theme.colors.janeGray}` }}>
    <ExpandMore style={{fill: `${theme.colors.white}`}} { ...props } />
  </div>
)
