import {getBaseApiHelper, postBaseApiHelper} from '../utils/apiHelpers'
import { addFlashMessage } from '../../components/FlashMessages/messages-actions'

export const REPORT_PREFERENCES_REQUEST = 'REPORT_PREFERENCES_REQUEST'
export const REPORT_PREFERENCES_SUCCESS = 'REPORT_PREFERENCES_SUCCESS'
export const REPORT_PREFERENCES_FAILURE = 'REPORT_PREFERENCES_FAILURE'

function reportPreferencesRequest(reportType, accountId) {
  return {
    type: REPORT_PREFERENCES_REQUEST,
    reportType,
    accountId
  }
}

function reportPreferencesSuccess(reportType, accountId, preferences) {
  return {
    type: REPORT_PREFERENCES_SUCCESS,
    reportType,
    accountId,
    preferences
  }
}

function reportPreferencesFailure(reportType, accountId, error) {
  return {
    type: REPORT_PREFERENCES_FAILURE,
    reportType,
    accountId,
    error
  }
}

export function getReportPreferences(reportType, accountId) {
  return dispatch => {
    dispatch(reportPreferencesRequest(reportType, accountId))
    return getBaseApiHelper(`/report_preferences/accounts/${accountId}/${reportType}`)
      .then(response => dispatch(reportPreferencesSuccess(reportType, accountId, response?.data?.preferences)))
      .catch(rejected => {
        dispatch(reportPreferencesFailure(reportType, accountId, rejected))
        return Promise.reject(rejected)
      })
  }
}

export function setReportPreferences(reportType, accountId, data) {
  return dispatch => {
    dispatch(reportPreferencesRequest(reportType, accountId, data))
    return postBaseApiHelper(`/report_preferences/accounts/${accountId}/${reportType}`, data)
      .then(response => {
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Update Success',
          text: `Successfully updated preferences for the ${reportType} report.`
        }))
        dispatch(reportPreferencesSuccess(reportType, accountId, response?.data?.preferences))
      })
      .catch(rejected => {
        dispatch(addFlashMessage({
          type: 'danger',
          title: `Unable to update preferences for the ${reportType} report, try again later`,
          text: 'Something went wrong.'
        }))
        dispatch(reportPreferencesFailure(reportType, accountId, rejected))
        return Promise.reject(rejected)
      })
  }
}
