export const readBlobToURI = (blob) => {
  return new Promise((resolve, reject)=>{
    let reader = new FileReader()
    reader.onload = (e)=>{
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

export const jsonb64stringToURI = (b64, mimeType = 'image/png') => {
  return `data:${mimeType};base64,${b64}`
}
