// Action Types
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE'
export const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE'

let nextMessageId = 1

// Action Creators
export function removeFlashMessage(messageId) {
  return {
    type: REMOVE_FLASH_MESSAGE,
    removeType: 'id',
    value: messageId
  }
}
export function removeFlashMessageByTitle(messageTitle) {
  return {
    type: REMOVE_FLASH_MESSAGE,
    removeType: 'title',
    value: messageTitle
  }
}

function setFlashMessage(message, id) {
  message = {
    ...message,
    id: id
  }
  return {
    type: ADD_FLASH_MESSAGE,
    message
  }
}

export function addFlashMessage(message) {
  return (dispatch) => {
    let curId = nextMessageId
    dispatch(setFlashMessage(message, curId))
    setTimeout(() => dispatch(removeFlashMessage(curId)), 7000)
    nextMessageId += 1
  }
}
