import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { StyledSideNavBarNavItem } from './styles'
import SideNavBar from './SideNavBar'



const MainNavLinks = ({ pathname, to, Icon, title }) => {
  return (
    <StyledSideNavBarNavItem>
      <NavLink to={to} className={(pathname === to) ? 'open' : ''}>
        <Icon />
        {title}
      </NavLink>
    </StyledSideNavBarNavItem>
  )
}

class NavBar extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    return (<SideNavBar {...this.props} />)
  }
}

NavBar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
  overrideStyle: PropTypes.shape({}),
  appType: PropTypes.string
}

MainNavLinks.propTypes = {
  Icon: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default NavBar
