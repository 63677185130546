import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './styles/index.css'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ConnectedRouter, routerMiddleware, routerReducer as router } from 'react-router-redux'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reducer as form } from 'redux-form'
import { createBrowserHistory } from 'history'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import { authReducer } from './components/auth/auth-reducer'
import metaReducer from './App/meta-reducer'
import messagesReducer from './components/FlashMessages/messages-reducer'
import entitiesReducer from './App/entities'
import {createOrderReducer} from './components/Orders/Create/create-actions-reducer'
import {createOrderReducer as createOrderReducerV2} from './components/Orders/Create/create-actions-reducer-v2'
import analyticsReducer from './components/Analytics/analytics-reducer'
import modalReducer from './components/Modal/modalReducer'
import mapsReducer from './components/FarmOverview/maps-reducer'
import ruleReducer from './components/RuleBuilder/rule-reducer'
import App from './App/App'
import FallbackComponent from './components/NotFound/ServerError'
import { muiTheme, theme } from './common/utils/theme'
import { LOGOUT_SUCCESS, setViewOnly } from './components/auth/auth-actions'
import { setBetaAccess } from './App/meta-actions'
import { ThemeProvider as Muitheme } from '@material-ui/styles'


export const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
let middleware = [thunkMiddleware,
  routeMiddleware]


// Set stage appropriately as Node_env will be set to production
// for any bundle created by 'build' react script
export let stage = process.env.REACT_APP_BASE_API_URL.includes('staging') ? 'staging' :
  process.env.REACT_APP_BASE_API_URL.includes('demo') ? 'demo' : 'production'

if (process.env.NODE_ENV !== 'production') {
  // add redux logging for local environment
  const { createLogger } = require(`redux-logger`)
  const loggerMiddleware = createLogger()
  const lm = loggerMiddleware
  middleware = [...middleware, lm]

  stage = 'development'
}
// Allow access to unreleased for non-prod environments
export const allowUnreleased = stage !== 'production'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const appReducer = combineReducers({
  auth: authReducer,
  form,
  router,
  meta: metaReducer,
  messagesReducer,
  createOrderState: createOrderReducer,
  createOrderStateV2: createOrderReducerV2,
  reportSettings: analyticsReducer,
  entities: entitiesReducer,
  modalReducer,
  mapSettings: mapsReducer,
  rules: ruleReducer
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined
  }
  return appReducer(state, action)
}

function configureStore(preloadedState = {}) {
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  )
}
const appStore = configureStore()

// Every bugsnag report will be accompanied by user details
const updateUserDetails = (report) => {
  let { auth } = appStore.getState()
  report.user = {
    id: auth.user.account_id,
    adminViewingUser: auth.viewingUser && `${auth.viewingUser.first_name} ${auth.viewingUser.last_name}`,
    name: `${auth.user.first_name} ${auth.user.last_name}`,
    email: auth.user.email
  }
}
//configure client and reporting boundary
const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || 'null',
  releaseStage: stage,
  beforeSend: updateUserDetails
})
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

//MessageListeners allow information to be consumed from internal app
const internalDomain = process.env.REACT_APP_INTERNAL_DOMAIN
const projectDomain = process.env.REACT_APP_PROJECT_DOMAIN
const messageListener = (evt) => {
  //handle actions from internal app
  if (![internalDomain, projectDomain].includes(evt.origin)) {
    return
  } else if (evt.data.messageType === 'viewAsUser') {
    //only refresh page if the account Id is being changed by message
    if (
      (evt.data.secureDetails.accountId !== window.sessionStorage.accountId)
      || evt.data.secureDetails.userId !== window.sessionStorage.userId
    ) {
      var refreshNeeded = true
    }
    //set session storage acct and user ids to be customer viewed
    //authToken and viewing user id are internal user's
    for (let i in evt.data.secureDetails) {
      window.sessionStorage[i] = evt.data.secureDetails[i]
    }
    //need to reload to trigger dashboard load with user data
    // (and navigate to dashboard if not previously logged in)
    refreshNeeded && window.location.replace(evt.data.secureDetails.endpoint || '/orders')
    appStore.dispatch(setBetaAccess(evt.data.secureDetails['unreleased']))
    appStore.dispatch(setViewOnly(!evt.data.secureDetails['edit']))
  }
}
window.addEventListener('message', messageListener)
if (window.opener){
  try {
    var target = window.opener.origin
  } catch (e) {
    // a cross origin error happens when trying to access the origin if the opener is on another origin
    // assume therefore that the other origin is the internal domain and that should be the target
    target = internalDomain
  }
  window.opener.postMessage({ messageType: 'loadState', value: 'true' }, target)
}

render(
  <ErrorBoundary FallbackComponent={()=><FallbackComponent history={history} />}>
    <Provider store={appStore}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <Muitheme theme={muiTheme}>
            <App bugs={bugsnagClient} />
          </Muitheme>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

export { bugsnagClient, appStore }
