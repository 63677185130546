import { createSelector } from 'reselect'

import { appStore } from '../../../index'
import {
  ORDER_SAMPLE_POST_SUCCESS,
} from '../../../common/actions-reducers/orders-actions'
import { createFarm, createFarms } from '../../../common/actions-reducers/farmActions'
import { createField, createFields } from '../../../common/actions-reducers/fieldActions'
import { postOrder, postOrders, postOrderSamples, postMultipleOrderSamples } from '../../../common/actions-reducers/orders-actions'
import { sortBy } from '../../../common/utils/sortHelpers'

import { ADD_GROWER_SUCCESS } from '../../../common/actions-reducers/permissionsActions'
import { MULTIPLE_ORDER_SAMPLES_POST_SUCCESS } from '../../../common/actions-reducers/orders-actions'

//////////////////
// ACTIONS
//////////////////
const RESET = 'RESET V2'
const SET_STEP = 'SET_STEP V2'
const SET_SELECTED_GROWER_ACCT = 'SET_SELECTED_GROWER_ACCT V2'
const SET_SELECTED_FARM = 'SET_SELECTED_FARM V2'
const SET_SELECTED_FIELD = 'SET_SELECTED_FIELD V2'
const SET_SAMPLE_DATA = 'SET_SAMPLE_DATA V2'
const IS_CREATING_ORDER = 'IS_CREATING_ORDER V2'
const ORDER_CREATION_SUCCESS = 'ORDER_CREATION_SUCCESS V2'
const ORDER_CREATION_FAILURE = 'ORDER_CREATION_FAILURE V2'
const SET_SELECTED_FARMS = 'SET_SELECTED_FARMS V2'
const SET_SELECTED_FIELDS = 'SET_SELECTED_FIELDS V2'
const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT V2'
const SET_NUM_SAMPLES = 'SET_NUM_SAMPLES V2'

// constants
export const CREATE_FARM = 'CREATE_FARM V2'
export const CREATE_FIELD = 'CREATE_FIELD V2'
export const SELECT_ALL_FARMS = 'SELECT_ALL_FARMS V2'
export const SELECT_ALL_FIELDS = 'SELECT_ALL_FIELDS V2'
export const MIN_SAMPLES_PER_FIELD = 1
export const MAX_SAMPLES_PER_FIELD = 100

export const CREATE_FARM_OPTION = {'value': CREATE_FARM, 'label': 'Create this farm as a new farm'}
export const CREATE_FIELD_OPTION = {'value': CREATE_FIELD, 'label': 'Create this field as a new field'}


export function reset() {
  return { type: RESET }
}

export function setStep(step) {
  return {
    type: SET_STEP,
    step
  }
}

export function setSelectedGrowerAccount(selectedGrowerAcct) {
  return {
    type: SET_SELECTED_GROWER_ACCT,
    selectedGrowerAcct
  }
}

export function setSelectedFarm(selectedFarm) {
  return {
    type: SET_SELECTED_FARM,
    selectedFarm
  }
}

export function setSelectedField(selectedField) {
  return {
    type: SET_SELECTED_FIELD,
    selectedField
  }
}

export function setSampleData(sampleData) {
  return {
    type: SET_SAMPLE_DATA,
    sampleData
  }
}

export function setSelectedFarms(selectedFarms) {
  return {
    type: SET_SELECTED_FARMS,
    selectedFarms
  }
}

export function setSelectedFields(selectedFields) {
  return {
    type: SET_SELECTED_FIELDS,
    selectedFields
  }
}

export function setSelectedProduct(selectedProduct) {
  return {
    type: SET_SELECTED_PRODUCT,
    selectedProduct
  }
}

export function setNumSamplesByFieldId(numSamplesByFieldId) {
  return {
    type: SET_NUM_SAMPLES,
    numSamplesByFieldId
  }
}

const isCreatingOrder = () => ({
  type: IS_CREATING_ORDER
})

const orderCreationSuccess = () => ({
  type: ORDER_CREATION_SUCCESS
})

const orderCreationFailure = (error) => ({
  type: ORDER_CREATION_FAILURE,
  error
})

export const createOrder = () => {
  const { createOrderStateV2, auth } = appStore.getState()
  const { selectedGrowerAcct, sampleData, selectedFarm, selectedField } = createOrderStateV2

  let orderId
  return dispatch => {
    dispatch(isCreatingOrder())
    return new Promise(resolve => {
      if (!selectedFarm?.id) {
        resolve(dispatch(createFarm(selectedFarm.name, selectedGrowerAcct)))
        // resolve(dispatch(createFarm(selectedFarm.name, selectedGrowerAcct?.id)))
      } else {
        resolve()
      }
    })
      .then(response => {
        if (!selectedField?.id) {
          return dispatch(createField({
            account_id: selectedGrowerAcct,
            // account_id: selectedGrowerAcct?.id,
            farm_id: response?.payload?.id || selectedFarm.id,
            name: selectedField?.name
          }))
        }
      })
      .then(response => dispatch(postOrder({
        ordering_account_id: auth.user.account_id,
        field_id: response?.id || selectedField.id
      })))
      .then(response => {
        orderId = response.id
        if (sampleData.length) {
          return dispatch(postOrderSamples(response.id, { samples: sampleData }))
        }
      })
      .then(() => {
        dispatch(orderCreationSuccess())
        return Promise.resolve({ orderId })
      })
      .catch(error => {
        dispatch(orderCreationFailure(error))
        return Promise.reject()
      })
  }
}

const createNewFieldsPromise = (dispatch, newFields, farms, farmNameMap, farmSelectOptionsByLabel, selectedGrowerAcct) => {
  if (newFields?.length) {
    const newFarmsByName = farms.reduce((acc, cur) => {
      acc[cur.name] = cur
      return acc
    }, {})
    const payloads = newFields.reduce((acc, cur) => {
      const [field, farm] = cur.split('::')
      const farmName = farmNameMap[farm]?.name || farm
      const farmData = newFarmsByName[farmName] || farmSelectOptionsByLabel[farmName]
      acc.push({
        name: field,
        account_id: farmData?.account_id,
        farm_id: farmData?.id
      })
      return acc
    }, [])
    return Promise.resolve(dispatch(createFields(selectedGrowerAcct.id, payloads)))
  } else {
    // no new fields
    return Promise.resolve()
  }
}

const createOrderForEachFieldPromise = (dispatch, auth, fieldIds) => {
  if (fieldIds?.length) {
    const payloads = fieldIds.reduce((acc, fieldId) => {
      acc.push({
        ordering_account_id: auth.user.account_id,
        field_id: fieldId
      })
      return acc
    }, [])
    return Promise.resolve(dispatch(postOrders(payloads)))
  } else {
    // no new fields
    return Promise.resolve()
  }
}

const createMultipleOrderSamplesPromise = (dispatch, getFieldIdByKey, newOrders, sampleData, selectedGrowerAcct) => {
  // aggregate samples by field ID and post samples to order with field ID
  if (sampleData.length && newOrders.length) {
    const ordersByFieldId = newOrders.reduce((acc, {id, field_id}) => {
      acc[field_id] = { orderId: id, data: { samples: [] } }
      return acc
    }, {})
    sampleData.forEach(s => {
      const fieldId = s.field_id || getFieldIdByKey(`${s.field_name}::${s.farm_name}`)
      const updatedSamples = ordersByFieldId[fieldId].data.samples.concat({
        ...s,
        field_id: fieldId,
        grower_id: selectedGrowerAcct.id
      })
      ordersByFieldId[fieldId] = {
        ...ordersByFieldId[fieldId],
        data: {
          samples: updatedSamples
        }
      }
    })

    const payloads = Object.values(ordersByFieldId)
    return Promise.resolve(dispatch(postMultipleOrderSamples(payloads)))
  } else {
    // no new data
    return Promise.resolve()
  }
}

export const createCSVOrderV2 = (accountId, newFarms, newFields, farmNameMap, fieldNameMap, farmSelectOptionsByLabel, fieldSelectOptionsByLabel) => {
  const { createOrderStateV2, auth } = appStore.getState()
  const { selectedGrowerAcct, sampleData } = createOrderStateV2

  let newFieldIdsByKey = {}

  const getFieldIdByKey = fieldFarmNameKey => {
    return fieldNameMap[fieldFarmNameKey]?.id || newFieldIdsByKey[fieldFarmNameKey] || fieldSelectOptionsByLabel[fieldFarmNameKey]?.id
  }

  return dispatch => {
    dispatch(isCreatingOrder())
    return new Promise(resolve => {
      // create farms
      if (newFarms.length) {
        resolve(dispatch(createFarms(selectedGrowerAcct.id, newFarms)))
      } else {
        // no new farms
        resolve()
      }
    })
      .then((response) => {
        const farms = response?.payload || []
        return createNewFieldsPromise(dispatch, newFields, farms, farmNameMap, farmSelectOptionsByLabel, selectedGrowerAcct)
      })
      .then(response => {
        const fields = response?.payload || []
        fields.forEach(({ farm, name, id }) => {
          newFieldIdsByKey[`${name}::${farm}`] = id
        })
        const allFieldIdsSet = sampleData.reduce((acc, s) => {
          const fieldId = getFieldIdByKey(`${s.field_name}::${s.farm_name}`)
          acc.add(fieldId)
          return acc
        }, new Set())
        return createOrderForEachFieldPromise(dispatch, auth, [ ...allFieldIdsSet ])
      })
      .then(response => {
        const newOrders = response?.order || []
        return createMultipleOrderSamplesPromise(dispatch, getFieldIdByKey, newOrders, sampleData, selectedGrowerAcct)
      })
      .catch(error => {
        dispatch(orderCreationFailure(error))
        return Promise.reject(error)
      })
  }
}

const createSamples = () => {
  const { createOrderStateV2 } = appStore.getState()
  const {
    selectedFields,
    selectedProduct,
    numSamplesByFieldId
  } = createOrderStateV2
  let samples = []

  // TODO: ensure backend handles samples with same name (in different fields)
  // let sampleNumber = 1

  selectedFields
    .filter(f => f.id)
    .forEach(({account_id, id}) => {
      let numSamples = numSamplesByFieldId[id]
      if (!numSamples) {
        numSamples = MIN_SAMPLES_PER_FIELD
      } else if (numSamples > MAX_SAMPLES_PER_FIELD) {
      // TODO: show error in order workflows
        numSamples = MAX_SAMPLES_PER_FIELD
      }

      for (let sampleNumber = 1; sampleNumber <= numSamples; sampleNumber++) {
        // for (; sampleNumber <= numSamples; sampleNumber++) {
        samples.push({
          field_id: id,
          grower_id: account_id,
          product_id: selectedProduct?.id,
          sample_name: `${sampleNumber}`
        })
      }
    })
  return samples
}

// Manual (single) order: create one order with multiple fields
export const createOrderV2 = () => {
  const { auth } = appStore.getState()
  const samples = createSamples()
  let orderId
  return dispatch => {
    dispatch(isCreatingOrder())
    return new Promise(resolve => {
      resolve(dispatch(postOrder({
        ordering_account_id: auth.user.account_id
      })))
    })
      .then(response => {
        orderId = response.id
        return dispatch(postOrderSamples(response.id, { samples: samples }))
      })
      .then(() => {
        dispatch(orderCreationSuccess())
        return Promise.resolve({ orderId })
      })
      .catch(error => {
        dispatch(orderCreationFailure(error))
        return Promise.reject()
      })
  }
}

// Manual (multiple) orders: create one order per field
export const createOrdersV2 = () => {
  const { createOrderStateV2, auth } = appStore.getState()
  const sampleData = createSamples()
  const { selectedFields, selectedGrowerAcct } = createOrderStateV2
  return dispatch => {
    dispatch(isCreatingOrder())
    return new Promise(resolve => {
      const fieldIds = selectedFields
        .filter(f => f.id)
        .map(f => f.id)
      resolve(createOrderForEachFieldPromise(dispatch, auth, fieldIds))
    })
      .then(response => {
        const newOrders = response?.order || []
        return createMultipleOrderSamplesPromise(dispatch, null, newOrders, sampleData, selectedGrowerAcct)
      })
      .catch(error => {
        dispatch(orderCreationFailure(error))
        return Promise.reject()
      })
  }
}

//////////////////
// REDUCER
//////////////////
const initialState = {
  step: 1,
  selectedGrowerAcct: null,
  selectedFarm: null,
  selectedField: null,
  sampleData: [],
  isCreatingOrder: false,
  serverError: null,
  selectedFarms: [],
  selectedFields: [],
  selectedProduct: null,
  numSamplesByFieldId: {}
}

export const createOrderReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case RESET: {
      return { ...initialState }
    }
    case SET_STEP: {
      return { ...state, step: action.step }
    }
    case SET_SELECTED_GROWER_ACCT: {
      return {
        ...state,
        selectedGrowerAcct: action.selectedGrowerAcct,
        selectedFarm: null,
        selectedField: null
      }
    }
    case SET_SELECTED_FARM: {
      return { ...state, selectedFarm: action.selectedFarm }
    }
    case SET_SELECTED_FARMS: {
      return { ...state, selectedFarms: action.selectedFarms }
    }
    case SET_SELECTED_FIELD: {
      return { ...state, selectedField: action.selectedField }
    }
    case SET_SELECTED_FIELDS: {
      return { ...state, selectedFields: action.selectedFields }
    }
    case SET_SELECTED_PRODUCT: {
      return { ...state, selectedProduct: action.selectedProduct}
    }
    case SET_NUM_SAMPLES: {
      return { ...state, numSamplesByFieldId: { ...state.numSamplesByFieldId, ...action.numSamplesByFieldId }}
    }
    case SET_SAMPLE_DATA: {
      return { ...state, sampleData: action.sampleData }
    }
    case ORDER_SAMPLE_POST_SUCCESS: {
      return { ...initialState }
    }
    case ORDER_CREATION_SUCCESS: {
      return { ...initialState }
    }
    case ORDER_CREATION_FAILURE: {
      return { ...state, isCreatingOrder: false, serverError: action.error }
    }
    case IS_CREATING_ORDER: {
      return { ...state, isCreatingOrder: true, serverError: null }
    }
    case ADD_GROWER_SUCCESS: {
      const { owning_account_id, account_name } = action.permission
      return {
        ...state,
        selectedGrowerAcct: { id: owning_account_id, name: account_name },
        selectedFarm: null,
        selectedField: null
      }
    }
    case MULTIPLE_ORDER_SAMPLES_POST_SUCCESS: {
      return { ...initialState }
    }
    default: {
      return state
    }
  }
}

//////////////////
// SELECTORS
//////////////////
const getSelectedGrowerAcct = state => state.createOrderStateV2.selectedGrowerAcct?.id
const getSelectedFarm = state => state.createOrderStateV2.selectedFarm
const getSelectedField = state => state.createOrderStateV2.selectedField

const getSelectedFarms = state => state.createOrderStateV2.selectedFarms

const getFarms = state => state.entities.farms.farmEntities
const getFields = state => state.entities.fields.fieldEntities
const filterFarmFieldOptions = (growerAcctId, selectedFarm, selectedField, farms, fields, selectedFarms) => {
  let farmsArray = sortBy(Object.values(farms[growerAcctId] || {}), el => el.name)
  farmsArray = farmsArray.map(farm => ({ ...farm, value: farm.name, label: farm.name }))
  if (farmsArray.length > 1) {
    farmsArray = [{'value': SELECT_ALL_FARMS, 'label': 'Select all'}].concat(farmsArray)
  }

  const selectedFarmIdsSet = new Set(selectedFarms.map(farm => farm.id))
  let fieldsArray = sortBy(Object.values(fields?.[growerAcctId] || {}).filter(field => selectedFarmIdsSet.has(field.farm_id)), el => el.name)
  fieldsArray = fieldsArray.map(field => ({ ...field, label: field.name }))
  const fieldsArrayWithCreateOption = [CREATE_FIELD_OPTION].concat([ ...fieldsArray ])
  if (fieldsArray.length > 1) {
    fieldsArray = [{'value': SELECT_ALL_FIELDS, 'label': 'Select all'}].concat(fieldsArray)
  }
  // if the selectedFarm/Field has no ID, it will be created and should be added to the select lists
  return {
    farms: (!!selectedFarm && !selectedFarm?.id) ? farmsArray.concat(selectedFarm) : farmsArray,
    fields: (!!selectedField && !selectedField?.id) ? fieldsArray.concat(selectedField) : fieldsArray,
    fieldsWithCreateOption: (!!selectedField && !selectedField?.id)
      ? fieldsArrayWithCreateOption.concat(selectedField)
      : fieldsArrayWithCreateOption
  }
}
export const getFarmFieldSelectOptions = createSelector(
  getSelectedGrowerAcct,
  getSelectedFarm,
  getSelectedField,
  getFarms,
  getFields,
  getSelectedFarms,
  filterFarmFieldOptions,
)

const filterFarmOptions = (growerAcctId, farms) => {
  let farmsArray = sortBy(Object.values(farms[growerAcctId] || {}), el => el.name)
  farmsArray = farmsArray.map(farm => ({ ...farm, value: farm.name, label: farm.name }))
  farmsArray = [CREATE_FARM_OPTION].concat(farmsArray)
  return farmsArray
}
export const getFarmSelectOptions = createSelector(
  getSelectedGrowerAcct,
  getFarms,
  filterFarmOptions
)

const filterFieldOptions = (growerAcctId, farms) => {
  let farmsArray = sortBy(Object.values(farms[growerAcctId] || {}), el => el.name)
  farmsArray = farmsArray.map(farm => ({ ...farm, value: farm.name, label: farm.name }))
  farmsArray = [CREATE_FARM_OPTION].concat(farmsArray)
  return farmsArray
}
export const getFieldSelectOptions = createSelector(
  getSelectedGrowerAcct,
  getFields,
  filterFieldOptions
)

const getUser = state => state.auth.user
const permissionedAccounts = state => state.entities.permissions.permissionedAccounts
const formatGrowerSelectOptions = (user, permissionedAccounts) => {
  const userName = `${user.first_name} ${user.last_name}`
  const options = [{ id: user.account_id, name: userName}]
  // TODO: include permissioned growers
  if (permissionedAccounts?.allIds.length) {
    permissionedAccounts.allIds.forEach(id => {
      const pa = permissionedAccounts.byId[id]
      if (pa?.activated && pa?.access_type === 'edit') {
        options.push({ id: pa.owning_account_id, name: pa.account_name})
      }
    })
  }
  return sortBy(options, el => el.name)
}

export const getGrowerSelectOptions = createSelector(
  getUser,
  permissionedAccounts,
  formatGrowerSelectOptions
)

export const getOrderEntities = (order=null) => {
  // There are two modes that need to return an equivilent data structure: during order creation and after
  // during order creation, return selected entities from create-actions-reducer
  // after order creation, return order entities
  let state = appStore.getState()
  let user = state.auth.user
  let createOrderState = state.createOrderStateV2
  let permissionedAccounts = state.entities.permissions.permissionedAccounts
  let selectedGrowerAcct = order ? order.grower_account_id : createOrderState.selectedGrowerAcct
  // grower is either the user or a permissioned account
  let grower = selectedGrowerAcct === user.account_id ? user : permissionedAccounts.byAccount?.[selectedGrowerAcct] || null
  let farm = order ? state.entities.farms.farmEntities?.[order.grower_account_id]?.[order.farm_id] : createOrderState.selectedFarm || null
  let field = order ? state.entities.fields.fieldEntities?.[order.grower_account_id]?.[order.field_id] : createOrderState.selectedField || null
  let orderSamples = order ? order.samples : createOrderState.sampleData || []
  return { grower, farm, field, orderSamples }
}
